import VideoPlayer from 'components/ui/video-player'
import { navigate } from 'gatsby'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

interface AccordionProps {
  dogNames: string
  isNewCustomer: boolean
  plan: any
}

const WelcomeMsgAccordion: React.FC<AccordionProps> = ({ dogNames, isNewCustomer = false, plan }) => {
  const [videoUrl, setVideoUrl] = useState(
    'https://res.cloudinary.com/different-dog/video/upload/v1707411389/Welcome_Video_full_diet_xbfnee.mp4'
  )
  useEffect(() => {
    if (plan?.length >= 1) {
      if (plan[0]?.planType.toLowerCase().includes('partial'))
        setVideoUrl(
          'https://res.cloudinary.com/different-dog/video/upload/v1707411423/Welcome_Video_partial_diet_xyol3x.mp4'
        )
    }
  }, [plan])

  return (
    <>
      <Helmet>
        {' '}
        {/* Cloudinary video player */}
        <link href="https://unpkg.com/cloudinary-video-player@1.10.5/dist/cld-video-player.min.css" rel="stylesheet" />
        <script
          src="https://unpkg.com/cloudinary-video-player@1.10.5/dist/cld-video-player.min.js"
          type="text/javascript"
        ></script>
      </Helmet>
      {isNewCustomer && (
        <>
          {videoUrl && (
            <div className="block lg:hidden mt-8 w-full pt-4">
              <div className="max-h-60 relative mx-auto h-60 rounded-2xl max-w-[1250px] w-full md:w-3/4">
                <div
                  className="image-backdrop-square-wrapper absolute h-60 w-full rounded-[20px]"
                  style={{ backgroundColor: '#6ba2b3' }}
                />
                <div className={`max-h-60 h-60 rounded-2xl video-rounded-corner`}>
                  <VideoPlayer videoLoop={true} videoSrc={videoUrl} />
                </div>
              </div>
            </div>
          )}
          <div
            className={`relative mx-auto mt-6 lg:mt-16 w-full rounded-3xl bg-thankyouBG md:w-3/4 lg:w-5/6 2xl:w-3/4 max-w-[1250px]`}
          >
            <div className="relative flex w-full flex-col overflow-visible lg:flex-row">
              <div className={`order-2 ml-0 flex-grow p-4 pb-8 text-sm md:order-2 md:pt-12 lg:-ml-8 lg:pt-0 lg:pb-0`}>
                <h2 data-testid="welcomeBack" className="font-third mb-6 -rotate-3 text-center text-5xl lg:text-left">
                  Welcome to the pack!
                </h2>
                <>
                  <p className="mb-6">
                    While our chefs get busy cooking <span className="font-bold">{dogNames}'s</span> new recipes, we
                    invite you to our{' '}
                    <Link
                      className="underline"
                      target="_blank"
                      rel="noopener"
                      to="https://www.facebook.com/groups/devotedtodogs"
                      onClick={() => {
                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'clicked_facebook_group', {
                            page: 'account_summary'
                          })
                        }
                      }}
                    >
                      Devoted to Dogs
                    </Link>{' '}
                    community group, where you can connect with like-minded owners, share your dog selfies and join live
                    sessions with our fantastic in-house experts.
                  </p>
                  <p className="mb-6">
                    Why not start earning points and money off your next box by telling your friends about Different
                    Dog? Visit our{' '}
                    <button
                      className="underline"
                      onClick={() => {
                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'clicked_rewards_page', {
                            page: 'account_summary_welcome_message'
                          })
                        }
                        navigate('/account/rewards/')
                      }}
                    >
                      rewards page
                    </button>{' '}
                    to get started.
                  </p>
                  <p className="pb-16 2xl:pb-12">
                    Our team are on hand to help you and your four-legged friend enjoy a smooth transition to their new
                    diet, so feel free to get in touch anytime.
                  </p>
                </>

                <p className="font-third absolute right-8 bottom-1 -rotate-3 pl-8 text-4xl lg:pl-0">
                  Charlie, Alex and Crumble.
                </p>
              </div>
              {videoUrl && (
                <div className="hidden lg:block mr-4 -ml-20 mt-8 w-full pt-4 2xl:w-3/4 2xl:mt-1 2xl:-ml-12">
                  <div className="max-h-60 relative mx-auto ml-5 h-60 w-5/6 rounded-[20px] max-w-[450px]">
                    <div
                      className="image-backdrop-square-wrapper absolute h-60 w-full rounded-[20px]"
                      style={{ backgroundColor: '#6ba2b3' }}
                    />
                    <div className={`max-h-60 h-60 rounded-[20px] video-rounded-corner`}>
                      <VideoPlayer videoLoop={true} videoSrc={videoUrl} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {!isNewCustomer && (
        <>
          <div
            className={`relative mx-auto mt-8 w-full rounded-3xl bg-thankyouBG md:mt-12 md:w-3/4 lg:w-5/6 2xl:w-1/2`}
          >
            <div className="relative flex w-full flex-col overflow-visible lg:flex-row">
              <div
                className={`order-2 ml-0 flex-grow p-4 pb-8 text-sm md:order-2 md:pt-12 lg:-ml-[16rem] lg:pt-0 lg:pb-0 2xl:-ml-[18rem]`}
              >
                <h2 data-testid="welcomeBack" className="font-third mb-6 -rotate-3 text-center text-5xl lg:text-left">
                  Welcome back!
                </h2>

                <>
                  <p className="mb-6">
                    We are so pleased that <span className="font-bold">{dogNames}</span>{' '}
                    {dogNames.includes('&') ? 'are' : 'is'} enjoying our recipes. We think they would love our highly
                    anticipated <span className="font-bold">new treats</span>, designed to freshen breath, aid
                    digestion, or ease anxiety. Learn more{' '}
                    <button
                      className="underline"
                      onClick={() => {
                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'clicked_treats_page', {
                            page: 'account_summary_welcome_message'
                          })
                        }
                        navigate('/treats/')
                      }}
                    >
                      here
                    </button>
                    .
                  </p>
                  <p className="mb-6">
                    Did you know you can save money on your next box by referring a friend? Visit our{' '}
                    <button
                      className="underline"
                      onClick={() => {
                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'clicked_rewards_page', {
                            page: 'account_summary_welcome_message'
                          })
                        }
                        navigate('/account/rewards/')
                      }}
                    >
                      rewards page
                    </button>{' '}
                    to get started.
                  </p>
                  <p className="pb-12">
                    Are you a member of our{' '}
                    <Link
                      className="underline"
                      target="_blank"
                      rel="noopener"
                      to="https://www.facebook.com/groups/devotedtodogs"
                      onClick={() => {
                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'clicked_facebook_group', {
                            page: 'account_summary'
                          })
                        }
                      }}
                    >
                      Devoted to Dogs
                    </Link>{' '}
                    community group? Join today to connect with like-minded dog owners, share pics of your furry{' '}
                    {dogNames.includes('&') ? 'friends' : 'friend'} and join live sessions with our fantastic in-house
                    experts.
                  </p>
                </>

                <p className="font-third absolute right-8 bottom-1 -rotate-3 pl-8 text-4xl lg:pl-0">
                  Charlie, Alex and Crumble.
                </p>
              </div>
              <StaticImage
                objectFit="contain"
                className="charlie-alex-crumble-image left-0 mx-auto w-full max-w-[250px] md:max-w-[400px] lg:-left-6 lg:m-4 lg:mr-56 lg:max-w-[300px] 2xl:-left-20 "
                src="../../../../images/charlieAlexAndDogs.png"
                alt="Charlie, Alex and Crumble"
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default WelcomeMsgAccordion
