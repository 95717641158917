import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import ErrorModal from 'components/ui/modal/ErrorModal'
import dayjs from 'dayjs'
import { Field, Form, Formik } from 'formik'
import { navigate } from 'gatsby'
import React, { Fragment, useRef, useState, useEffect } from 'react'
import { CalendarContainer } from 'react-datepicker'
import { DatePicker } from 'react-formik-ui'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import {
  getPlanSchedule,
  postReschedulePlan,
  resetPlansAndOrders,
  updatePlans
} from 'state/actions/account/accountPlanAction'
import { fetchPlanSchedule } from 'state/apis/account/planApi'

export default function EditPlanModal({
  show,
  setShow,
  showReschedule,
  setShowReschedule,
  subscriptionId,
  status,
  nextDelivery,
  validDeliveryDate
}) {
  const cancelButtonRef = useRef(null)
  const { postReschedulePlanStatus, errorMessage, planFailError } = useSelector((state: RootState) => state.accountPlan)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const dispatch = useDispatch()

  const [shippingDates, setShippingDates] = useState([])

  var formikRef = useRef<any>()

  const [activeDeliveryPrice, setActiveDeliveryPrice] = useState(validDeliveryDate?.price || 0)
  const [priceDateChange, setPriceDateChange] = useState('')

  useEffect(() => {
    const weekdays = shippingDates.filter(date => date.price != '499')
    if (weekdays.find(day => day.price === 399)) {
      const priceChangeDate = weekdays.find(day => day.price === 399)
      if (priceChangeDate && weekdays.find(day => day.price === 0)) {
        const orderDate = weekdays.findIndex(day => day.price === 399)
        const shippingDate = weekdays[orderDate + 4]
        setPriceDateChange(dayjs(shippingDate.date).format('Do MMMM'))
      }
    }
  }, [shippingDates, priceDateChange])

  useEffect(() => {
    const getRescheduleDates = async () => {
      const dates = await fetchPlanSchedule(subscriptionId)
      setShippingDates(dates.shippingDates)
    }

    if (subscriptionId) {
      getRescheduleDates()
    }
  }, [])

  useEffect(() => {
    const redirectIfNoRescheduleError = async () => {
      if (postReschedulePlanStatus && postReschedulePlanStatus === 'success') {
        if (!errorMessage) {
          setShowReschedule(false)
          setShow(false)

          await dispatch(updatePlans())
          const url = spoofMode ? `/account/plan/?userId=` + spoofedUserId : '/account/plan/'
          navigate(url)
        }
      }
    }

    redirectIfNoRescheduleError()
  }, [postReschedulePlanStatus])

  const [_showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    if (planFailError || errorMessage) {
      setShowPopup(true)
    } else {
      setShowPopup(false)
    }
  }, [planFailError, errorMessage])

  useEffect(() => {
    if (formikRef?.current?.values?.shippingDates) {
      const selectedDate: any[] = shippingDates?.filter(
        (dateFromShippingDates: any) =>
          dayjs(dateFromShippingDates.date).format('DD/MM/YYYY') ===
          dayjs(formikRef?.current?.values?.shippingDates).format('DD/MM/YYYY')
      )

      if (selectedDate.length === 1) {
        setActiveDeliveryPrice(selectedDate[0].price)
      }
    }
  }, [formikRef?.current?.values?.shippingDates])

  const getDeliveryDateText = () => {
    return dayjs(formikRef?.current?.values?.shippingDates).format('dddd, Do MMMM YYYY')
  }

  const getBillingDate = () => {
    if (formikRef.current && formikRef.current.values.shippingDates) {
      const date = dayjs(formikRef.current.values.shippingDates).subtract(4, 'day')
      return date.isBefore(dayjs()) ? 'Today' : date.format('dddd, Do MMMM YYYY')
    }
    return ''
  }

  return (
    <Transition.Root show={show} as={'div'}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => setShow(false)}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0">
          <Transition.Child
            as={'div'}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden align-middle sm:inline-block sm:h-full" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={'div'}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className={'w-full'}
          >
            <div className="relative inline-block w-full max-w-xl transform rounded-3xl bg-white p-4 text-left align-middle shadow-xl transition-all sm:my-8 sm:p-8">
              <div
                className={`absolute top-0 right-0 flex h-12 w-12 cursor-pointer flex-col items-center justify-center p-2 md:top-4 md:right-4`}
                onClick={() => setShow(false)}
              >
                <FontAwesomeIcon icon={faClose} fontSize={28} />
              </div>
              <div>
                <div>
                  <div data-testid="whatWouldYouLikeToDo">
                    {!showReschedule && <h3 className="mb-8 text-center">What would you like to do?</h3>}
                    {showReschedule && (
                      <h3 data-testid="pleaseSelect" className="mx-4 md:mx-0 mb-8 text-center font-sans font-bold">
                        Please select your new delivery date
                      </h3>
                    )}
                    <Formik
                      innerRef={formikRef}
                      initialValues={{
                        editPlanRadio: '',
                        shippingDates: nextDelivery ? new Date(nextDelivery) : null,
                        sendEmailNotification: true
                      }}
                      onSubmit={async (formikValues, { setSubmitting }) => {
                        if (showReschedule) {
                          setSubmitting(true)
                          try {
                            await dispatch(
                              postReschedulePlan({
                                subscriptionId,
                                nextDelivery: dayjs(formikValues.shippingDates).format('YYYY-MM-DD'),
                                sendEmailNotification: formikValues.sendEmailNotification
                              })
                            )
                          } catch (e) {
                            console.error(JSON.stringify(e, null, 2))
                          }
                          setSubmitting(false)
                        }

                        if (formikValues.editPlanRadio === 'moveDate') {
                          setSubmitting(false)
                          setShowReschedule(true)

                          console.log(formikValues.editPlanRadio, showReschedule)
                        } else if (formikValues.editPlanRadio === 'pausePlan') {
                          const url = spoofMode
                            ? `/account/plan/${subscriptionId}/status/pause/?userId=` + spoofedUserId
                            : `/account/plan/${subscriptionId}/status/pause/`
                          navigate(url)
                          setSubmitting(false)
                        } else if (formikValues.editPlanRadio === 'cancelPlan') {
                          const url = spoofMode
                            ? `/account/plan/${subscriptionId}/status/cancel/?userId=` + spoofedUserId
                            : `/account/plan/${subscriptionId}/status/cancel/`
                          navigate(url)
                          setSubmitting(false)
                        }
                      }}
                    >
                      {props => {
                        function checkInputState(fieldName: string) {
                          if (!props.errors[fieldName] && props.values[fieldName]) {
                            return 'field-success-alternate'
                          } else if (props.errors?.[fieldName] && props.touched?.[fieldName]) {
                            return 'field-error-alternate'
                          }
                          return 'field-focus'
                        }
                        return (
                          <Form>
                            {!showReschedule && (
                              <div className="flex w-full flex-col items-center">
                                <button
                                  className={`btn-primary focus mb-4 w-full justify-center rounded-full border-2 p-2 text-center !drop-shadow-none`}
                                  onClick={() => {
                                    props.setFieldValue('editPlanRadio', 'moveDate')
                                    if (typeof window.gtag !== 'undefined') {
                                      window.gtag('event', 'select_move_delivery', {
                                        page: 'modal_edit_plan',
                                        subscription_id: subscriptionId
                                      })
                                    }

                                    props.handleSubmit()
                                  }}
                                  type="button"
                                >
                                  Move my delivery sooner or later
                                </button>
                              </div>
                            )}
                            {showReschedule && (
                              <>
                                <div className="mb-2">
                                  {/* @ts-ignore This library has some type conflicts with one of its dependency */}
                                  <DatePicker
                                    data-testid="shippingDates"
                                    name="shippingDates"
                                    disabled={shippingDates?.length === 0}
                                    placeholder="Select Date"
                                    calendarStartDay={1}
                                    value={getDeliveryDateText()}
                                    includeDates={shippingDates?.map(date => new Date(date.date))}
                                    className={`${checkInputState(
                                      'shippingDates'
                                    )} z-10 peer block w-full self-center rounded-full border border-gray-300 bg-white py-3 text-center focus:border-highlight focus:ring-highlight pr-6 lg:pr-0`}
                                    inputMode="none"
                                    onFocus={(e: any) => e.target.blur()}
                                    dayClassName={(date: any) => {
                                      const dateObj = new Date(date)
                                      const validDate = shippingDates?.filter(
                                        dateFromShippingDates =>
                                          dayjs(dateFromShippingDates.date).format('DD/MM/YYYY') ===
                                          dayjs(date).format('DD/MM/YYYY')
                                      )

                                      if (validDate.length === 1 && dateObj.getDay() === 6) {
                                        return '!text-red-500'
                                      } else if (validDate.length === 0 && dateObj.getDay() === 6) {
                                        return '!text-red-300'
                                      } else {
                                        return undefined
                                      }
                                    }}
                                    calendarContainer={({ className, children }) => (
                                      <div>
                                        <CalendarContainer className={className}>
                                          <div style={{ position: 'relative' }}>{children}</div>
                                          <div className="w-[240px] p-2 pt-0">
                                            <span className="w-auto font-bold">
                                              {activeDeliveryPrice === 0 &&
                                                !priceDateChange &&
                                                'Free weekday delivery on first order'}
                                              {(activeDeliveryPrice === 0 || activeDeliveryPrice === 399) &&
                                                priceDateChange &&
                                                'Free weekday delivery before ' +
                                                  priceDateChange +
                                                  ', £3.99 after this date'}
                                              {activeDeliveryPrice === 399 &&
                                                !priceDateChange &&
                                                '£3.99 Weekday delivery'}
                                            </span>
                                            <span className="flex w-full font-bold text-red-400">
                                              £4.99 - Saturday delivery
                                            </span>
                                          </div>
                                        </CalendarContainer>
                                      </div>
                                    )}
                                  />
                                  {props.errors.shippingDates && props.touched.shippingDates ? (
                                    <div className="text-xs text-red-500">{props.errors.shippingDates}</div>
                                  ) : null}
                                </div>
                                {formikRef?.current?.values?.shippingDates && (
                                  <div className="flex justify-center items-center flex-row">
                                    <p className="font-bold text-sm lg:text-base font-sans">Billing date: </p>
                                    <span className="font-medium text-sm lg:text-base font-sans">
                                      &nbsp;{getBillingDate()}
                                    </span>
                                  </div>
                                )}
                                {spoofMode && (
                                  <div className="flex justify-center items-center flex-row mt-6">
                                    <label className="flex flex-row items-center">
                                      <Field
                                        id="sendEmailNotification"
                                        type="checkbox"
                                        name="sendEmailNotification"
                                        className="text-fourth-checkbox  h-4 w-4 rounded-[4px] ml-1"
                                        onClick={(e: any) => {
                                          props.setFieldValue('sendEmailNotification', e.target.checked)
                                        }}
                                      />
                                      <span className="flex-wrap pl-2 text-md">Send confirmation email</span>
                                    </label>
                                  </div>
                                )}
                              </>
                            )}
                            {showReschedule && (
                              <div className="mt-2">
                                {activeDeliveryPrice === 3.99 && <span>+£3.99 - Weekday delivery</span>}
                                {activeDeliveryPrice === 4.99 && (
                                  <span className="text-red-500">+£4.99 - Saturday delivery</span>
                                )}
                              </div>
                            )}
                            {!showReschedule && (
                              <>
                                <div className={`${status === 'Active' ? 'block' : 'hidden'}`}>
                                  <div className="flex w-full flex-col items-center">
                                    <button
                                      className={`btn-primary focus mb-4 w-full justify-center rounded-full border-2 p-2 text-center !drop-shadow-none`}
                                      onClick={() => {
                                        props.setFieldValue('editPlanRadio', 'pausePlan')
                                        if (typeof window.gtag !== 'undefined') {
                                          window.gtag('event', 'select_pause_plan', {
                                            page: 'modal_edit_plan',
                                            subscription_id: subscriptionId
                                          })
                                        }

                                        props.handleSubmit()
                                      }}
                                      type="button"
                                    >
                                      Pause my plan
                                    </button>
                                  </div>
                                </div>
                                <div className={`${status === 'Future' || status === 'Active' ? 'block' : 'hidden'}`}>
                                  <div className="flex w-full flex-col items-center">
                                    <button
                                      data-testid="cancelPlan"
                                      className={`btn-primary focus mb-8 w-full justify-center rounded-full border-2 p-2 text-center !drop-shadow-none`}
                                      onClick={() => {
                                        props.setFieldValue('editPlanRadio', 'cancelPlan')
                                        if (typeof window.gtag !== 'undefined') {
                                          window.gtag('event', 'select_cancel_plan', {
                                            page: 'modal_edit_plan',
                                            subscription_id: subscriptionId
                                          })
                                        }

                                        props.handleSubmit()
                                      }}
                                      type="button"
                                    >
                                      Cancel my plan
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="mx-auto grid grid-cols-3">
                              <div className="col-start-2 inline-flex justify-center">
                                <button
                                  type="button"
                                  className="btn-secondary"
                                  onClick={() => {
                                    setShow(false)
                                    setShowReschedule(false)
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>

                              {showReschedule && (
                                <div className="col-start-3 flex justify-end">
                                  <button
                                    data-testid="planEditNext"
                                    id="planEditNext"
                                    type="submit"
                                    disabled={props.isSubmitting}
                                    className="btn-cta inline-flex justify-center disabled:opacity-40"
                                  >
                                    Save
                                  </button>
                                </div>
                              )}
                            </div>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
