import { navigate } from '@reach/router'
import { extractPublicId } from 'cloudinary-build-url'
import TrustPilotMicroCombo from 'components/ui-builder/trustpilot/micro-combo'
import TrustPilotMini from 'components/ui-builder/trustpilot/mini'
import AddTreatConfirmModal from 'components/ui/modal/AddTreatConfirmModal'
import ErrorModal from 'components/ui/modal/ErrorModal'
import RecipeOrTreatAlterModal from 'components/ui/modal/RecipeOrTreatModalAlter'
import SelectPlanAddTreatModal from 'components/ui/modal/SelectPlanAddTreatModal'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import TrustBoxAlternate from 'components/ui/trustpilot/TrustBoxAlternate'
import CloudinaryImage from 'components/util/CloudinaryImage'
import { Field, Formik } from 'formik'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { createDogsExistingPlan } from 'state/actions/account/flow/dogAction'
import { populateTreatDataOnly, prefillAddons } from 'state/actions/account/flow/planAction'
import { createShippingConfirmDogsExistingAddons } from 'state/actions/account/flow/shippingAction'
import { PlanType } from 'state/types/planType'
import { TreatsSchema } from 'utils/yup-validation-schema'
import BulletPointImage from '../../../../images/BulletPoint.svg'
import limitedEditionSticker from '../../../../images/limited-edition-sticker.svg'
import loadingGIF from '../../../../images/loadingspinner.gif'
import NewSticker from '../../../../images/new-sticker.svg'
import { getAccountSummary, resetAccountSummary } from '../../../../state/actions/accountSummary'

const AddTreats = () => {
  const {
    summary
  }: {
    summary: AccountSummaryType
  } = useSelector((state: RootState) => state.accountSummary)
  const {
    plan,
    addonsSelected
  }: {
    plan: PlanType
    addonsSelected: any[]
  } = useSelector((state: RootState) => state.accountDogPlanFlow)
  const { dogInfo, loading }: any = useSelector((state: RootState) => state.accountDogFlow)
  const { error, errorMessage } = useSelector((state: RootState) => state.accountDogShippingFlow)
  const [addons, setAddons] = useState<Array<{ id: string; quantity: number; price: number; name: string }>>([])
  const [addonCount, setAddonCount] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [addTreatModalShow, setAddTreatModalShow] = useState(false)
  const [modalActiveRecipe, setModalActiveRecipe] = useState({})
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const [submitting, setSubmitting] = useState(false)
  const [showSelectPlanModal, setShowSelectPlanModal] = useState(false)
  const [subscriptionId, setSubscriptionId] = useState('')
  const dispatch = useDispatch()
  const activePlans = summary?.plans?.filter(p => p.status === 'active' || p.status === 'future')
  const [addonChange, setAddonChange] = useState(0)
  const [addonValue, setAddonValue] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    // Get account summary to load redux
    const getAccountSummaryAndOrders = () => {
      setIsLoading(true)
      setSubscriptionId('')
      dispatch(resetAccountSummary())
      dispatch(getAccountSummary())
      setIsLoading(false)
    }
    getAccountSummaryAndOrders()
  }, [])

  useEffect(() => {
    // Handle plan, multi plan, or no plan load
    if (!subscriptionId) {
      setIsLoading(true)
      if (activePlans?.length === 1) {
        setSubscriptionId(activePlans[0]?.subscriptionId)
        setIsLoading(false)
      }
      if (activePlans?.length > 1) {
        setIsLoading(false)
        setShowSelectPlanModal(true)
      }
      if (activePlans?.length < 1) {
        const url = spoofMode ? '/account/plan/?userId=' + spoofedUserId : '/account/plan/'
        navigate(url)
      }
    }
  }, [activePlans, summary, subscriptionId])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  useEffect(() => {
    // Load dogInfo redux
    if (subscriptionId) {
      dispatch(
        createDogsExistingPlan({
          subscriptionId: subscriptionId,
          dogs: []
          // addonsOnly: true
        })
      )
    }
  }, [subscriptionId])

  useEffect(() => {
    // Load treats
    if (dogInfo?.defaultPackSize?.default && plan?.addons?.length === 0) {
      const planType = dogInfo.defaultPackSize.default.toLowerCase().replace('_t', 'T')
      dispatch(populateTreatDataOnly({ plan: dogInfo.defaultPackSize[planType] }))
    }
  }, [dogInfo, subscriptionId])

  useEffect(() => {
    if (errorMessage || errorMessage?.popup) {
      setAddTreatModalShow(false)
      setShowPopup(true)
    }
  }, [errorMessage])

  const setSelectedPlan = (subId: string) => {
    // Handle selected plan from modal if multi plan user
    setSubscriptionId(subId)
    setShowSelectPlanModal(false)
  }

  const getDogNames = (dogs: any) => {
    return dogs
      .filter(d => d.name)
      .map(d => d.name)
      .join(', ')
      .replace(/,(?!.*,)/gim, ' &')
  }

  const backClick = () => {
    const url = spoofMode ? '/account/plan/?userId=' + spoofedUserId : '/account/plan/'
    navigate(url)
  }

  const disableAddonAddButton = (addonId: string, outOfStock: boolean) => {
    const index = addons.findIndex(addon => addon.id === addonId)
    const previousAddonsIndex = plan.addons.findIndex((addon: any) => addon.id === addonId)

    if (index !== -1) {
      const addonsArr = [...addons]
      if (outOfStock) {
        if (addonsArr[index].quantity >= plan.addons[previousAddonsIndex].quantity) {
          return true
        }
      }
    } else {
      if (outOfStock) {
        if (plan.addons[previousAddonsIndex].quantity > 0) {
          return false
        }
        return true
      }
    }
    return false
  }

  const handleAddAddon = (addonId: string, price: number, outOfStock: boolean, name: string) => {
    if (addons) {
      const index = addons.findIndex(addon => addon.id === addonId)
      const previousAddonsIndex = plan.addons.findIndex((addon: any) => addon.id === addonId)

      if (index !== -1) {
        const addonsArr = [...addons]

        if (outOfStock) {
          if (addonsArr[index].quantity < plan.addons[previousAddonsIndex].quantity) {
            addonsArr[index].quantity = addonsArr[index].quantity + 1
            setAddons(addonsArr)
            setAddonCount(curr => curr + 1)
          }
        } else {
          addonsArr[index].quantity = addonsArr[index].quantity + 1
          setAddons(addonsArr)
          setAddonCount(curr => curr + 1)
        }
      } else {
        if (!outOfStock) {
          setAddons([...addons, { id: addonId, quantity: 1, price, name }])
          setAddonCount(curr => curr + 1)
        } else {
          if (plan.addons[previousAddonsIndex].quantity > 0) {
            setAddons([...addons, { id: addonId, quantity: 1, price, name }])
            setAddonCount(curr => curr + 1)
          }
        }
      }
    } else {
      if (!outOfStock) {
        setAddons([{ id: addonId, quantity: 1, price, name }])
        setAddonCount(curr => curr + 1)
      }
    }

    if (typeof window.gtag !== 'undefined' && plan?.addons?.length > 0) {
      const index = plan?.addons?.findIndex(addon => addon.id === addonId)
      const addonsIndex = addons?.findIndex(addon => addon.id === addonId)
      window.gtag('event', 'added_treat', {
        page: 'account_addon_add_treats',
        addon_id: plan.addons[index].id,
        addon_name: plan.addons[index].name,
        quantity: addonsIndex === -1 ? 1 : addons[addonsIndex].quantity
      })
    }
  }

  const handleRemoveAddon = (addonId: string) => {
    if (addons) {
      const index = addons.findIndex(addon => addon.id === addonId)
      const plansAddonsIndex = plan?.addons?.findIndex(addon => addon.id === addonId)

      if (index !== -1) {
        if (addons[index].quantity > 1) {
          const addonsArr = [...addons]
          addonsArr[index].quantity = addonsArr[index].quantity - 1
          setAddons(addonsArr)
        } else {
          setAddons(addons.filter(addon => addon.id !== addonId))
        }
        setAddonCount(curr => curr - 1)

        if (typeof window.gtag !== 'undefined' && plan?.addons?.length > 0) {
          window.gtag('event', 'removed_treat', {
            page: 'account_addon_add_treats',
            addon_id: plan?.addons[plansAddonsIndex].id,
            addon_name: plan?.addons[plansAddonsIndex].name,
            quantity: addons[index].quantity - 1
          })
        }
      }
    }
  }

  const getAddonQuantity = (addonId: string) => {
    if (addons) {
      const index = addons.findIndex(addon => addon.id === addonId)
      if (index !== -1) {
        return addons[index].quantity
      }
    }
    return 0
  }

  const getDiscountedAddonPrice = (price: number, treatId: string) => {
    const discountAppliedToAll = dogInfo?.defaultCoupon?.applicableIds?.length === 0 ? true : false

    let total = 0
    if (price > 0 && dogInfo.defaultCoupon?.code) {
      if (dogInfo.defaultCoupon.type === 'percentage') {
        if (discountAppliedToAll) {
          total = price - (dogInfo.defaultCoupon.amount / 100) * price
        } else {
          if (dogInfo?.defaultCoupon?.applicableIds.includes(treatId)) {
            total = price - (dogInfo.defaultCoupon.amount / 100) * price
          } else {
            total = price
          }
        }
      } else {
        total = price
      }
    }
    return total
  }

  useEffect(() => {
    if (addonsSelected?.length > 0) {
      let totalAddons = 0
      setAddons(addonsSelected)
      addonsSelected.forEach(addon => {
        totalAddons += addon.quantity
      })
      setAddonCount(totalAddons)
      // Treat count on page entry
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'treat_count_begin', {
          page: 'account_addon_add_treats',
          quantity: totalAddons
        })
      }
      setAddonChange(totalAddons)
    } else {
      if (subscriptionId) {
        // Treat count on page entry
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'treat_count_begin', {
            page: 'account_addon_add_treats',
            quantity: addonCount
          })
        }
        setAddonChange(addonCount)
      }
    }
  }, [addonsSelected])

  useEffect(() => {
    if (plan && addonsSelected?.length === 0) {
      const addonsSelectedLocal: any = []
      let totalAddons = 0
      plan.addons.map(addon => {
        if (addon.quantity > 0) {
          addonsSelectedLocal.push(addon)
          totalAddons += addon.quantity
        }
      })

      if (addonsSelectedLocal?.length > 0) {
        setAddonCount(totalAddons)
        dispatch(prefillAddons(addonsSelectedLocal))
        let addonTotal = 0
        addonsSelectedLocal.map(a => {
          addonTotal += a.quantity * a.price
        })
        setAddonValue(addonTotal)
      }
    }
  }, [plan])

  const getSelectedAddons = () => {
    const selectedAddons = addons.map(addon => {
      return { id: addon.id, quantity: addon.quantity }
    })
    return selectedAddons
  }

  if (isLoading) {
    return (
      <div className="m-auto w-full text-center" style={{ marginTop: '25vh' }}>
        <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
      </div>
    )
  }

  return (
    !isLoading && (
      <>
        <div>
          <Formik
            initialValues={{ treats: [] }}
            validationSchema={TreatsSchema}
            onSubmit={async () => {
              setSubmitting(true)
              const selectedAddons = getSelectedAddons()
              const dataToForward: any = {
                addons: selectedAddons,
                subscriptionId: subscriptionId,
                source: 'account'
              }
              await dispatch(createShippingConfirmDogsExistingAddons(dataToForward))
              // Treat count on submit
              if (typeof window.gtag !== 'undefined') {
                window.gtag('event', 'treat_count_end', {
                  page: 'account_addon_add_treats',
                  quantity: addonCount
                })
              }

              let addonTotal = 0
              addons.map(a => {
                addonTotal += a.quantity * a.price
              })
              // Treat difference
              if (typeof window.gtag !== 'undefined') {
                window.gtag('event', 'treat_count_difference', {
                  page: 'account_addon_add_treats',
                  difference: addonCount - addonChange,
                  value: addonTotal - addonValue
                })
              }
              if (!errorMessage) {
                setAddTreatModalShow(true)
              }
              setSubmitting(false)
            }}
            validateOnMount
          >
            {props => {
              const getPackSize = (planDescription: string) => {
                if (!planDescription) return
                let index = planDescription.lastIndexOf('(')
                let splitArr = planDescription.split('(', index)
                let description = splitArr?.[0]?.trim()
                let packSize = splitArr?.length && splitArr[splitArr.length - 1]
                splitArr = (packSize && packSize?.split(')')) || []
                packSize = splitArr?.length && splitArr[0].trim()
                return [description, packSize]
              }
              const outOfStock = plan?.addons[0]?.status === 'OUTOFSTOCK'

              return (
                <div className="relative">
                  <div className="container mx-auto md:mx-auto">
                    <div className="mb-4">
                      <h1
                        data-testid="howAboutSomeTreats"
                        className="p-4 text-center text-3xl tracking-tight sm:text-5xl"
                      >
                        How about some treats?
                      </h1>
                      <p className="text-center">
                        Level up your training with natural, high-value treats and boost their health with
                        nutritionist-approved probiotics.
                      </p>
                    </div>
                    {subscriptionId && (
                      <div className="md:p-4">
                        <div
                          className={`mx-[5%] -mb-4 max-w-[90%] justify-center rounded-3xl p-2 text-center ${
                            getAddonQuantity(plan?.addons[0]?.id) > 0 ? `-mb-3` : 'mb-0'
                          } `}
                        >
                          <h1
                            data-testid="ourRecommendation"
                            className="inline-block rounded-t-3xl bg-[#C6DABB] p-2 px-6 text-sm tracking-tight md:text-2xl"
                          >
                            We think {getDogNames(dogInfo.dogs)} will love
                          </h1>
                        </div>
                        <div
                          className={`my-2 ml-[2%] -mr-[2%] flex h-[26.5rem] w-[96%] flex-wrap overflow-hidden bg-checkoutBG md:h-96 lg:h-72 lg:min-w-[900px] lg:flex-nowrap xl:h-64 ${
                            getAddonQuantity(plan?.addons[0]?.id) > 0 ? `shadow-[0_0_0_4px_rgba(0,102,133,1)]` : ''
                          } flex flex-wrap rounded-3xl lg:mx-auto lg:w-2/3`}
                        >
                          <div className="relative flex h-1/2 w-1/2 pl-4 pt-4 md:h-[64%] md:pl-8 lg:h-full lg:w-full lg:max-w-[256px] lg:pl-0 lg:pt-0">
                            <CloudinaryImage
                              publicId={extractPublicId(plan?.addons[0]?.images?.secondary)}
                              alt={plan?.addons[0]?.name}
                              steps={[400]}
                              className="lg:w[100%] rounded-3xl object-top md:h-[86%] md:w-[86%] block lg:h-[100%] lg:rounded-none lg:object-cover"
                            />
                            {plan?.addons[0]?.limitedEdition && (
                              <img
                                className="absolute top-4 right-4 w-1/2"
                                src={limitedEditionSticker}
                                alt="Limited Edition"
                              />
                            )}
                            {plan?.addons[0]?.isNew && (
                              <span
                                style={{ lineHeight: '1rem', right: '0.5rem' }}
                                className="index-1 absolute -top-4 left-4 md:-top-0 lg:left-0 inline-flex h-24 w-24 -rotate-12 md:h-20 md:w-20 lg:top-0 lg:h-20 lg:w-20 xl:inline-flex"
                              >
                                <img
                                  className="w-1/2 max-w-[75px] -rotate-12 object-contain md:w-full"
                                  src={NewSticker}
                                  alt="New"
                                />
                              </span>
                            )}
                          </div>

                          <div className="flex h-1/3 w-1/2 justify-between p-4 pb-0 md:p-4 md:pb-0 lg:w-full lg:px-6 lg:pb-4 xl:p-6">
                            <div className="ml-0 w-full md:-ml-2 lg:-ml-6">
                              <div className="mb-4 flex items-center">
                                <h4 className="text-xl text-primary">{plan?.addons[0]?.name}</h4>
                              </div>
                              <div className="mb-2 flex flex-col space-x-0 lg:flex-row lg:space-x-2.5">
                                {plan?.addons[0]?.highlights &&
                                  plan?.addons[0]?.highlights?.map((highlight: any, i: number) => {
                                    return (
                                      <span
                                        key={i}
                                        className="mb-2 flex flex-initial justify-center whitespace-nowrap rounded-full bg-[#FCD1CD] py-2 px-2 text-center text-xs xs:w-full md:text-sm lg:mb-0"
                                      >
                                        {highlight}
                                      </span>
                                    )
                                  })}
                              </div>
                              <p className="mb-4 flex w-full items-center text-left text-xs text-black md:text-sm">
                                {getPackSize(plan?.addons[0]?.description)?.[0] ?? ''}
                              </p>
                              <div className="mb-0">
                                {plan?.addons[0]?.bullets && plan?.addons[0]?.bullets?.length > 0 && (
                                  <ul className="mt-4 block w-full items-center justify-center gap-x-4 md:flex-row ">
                                    {plan?.addons[0]?.bullets?.map((bullet: string, i: number) => (
                                      <li
                                        key={i}
                                        className="mx-1 inline-flex flex-1 items-center justify-center md:flex-initial"
                                      >
                                        <img width={20} src={BulletPointImage} alt={bullet} />
                                        <span className="ml-2 text-xs md:text-sm">{bullet}</span>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mt-12 ml-0 flex hidden h-1/2 w-1/2 flex-col items-center gap-y-4 text-left md:-mt-4 md:-ml-2 md:flex lg:mr-8 lg:mt-12 lg:ml-0 lg:w-1/3">
                            {(plan?.addons[0]?.price / 100)?.toFixed(2) !== 'NaN' && (
                              <p className={`text-xl font-bold lg:text-2xl`}>
                                {getDiscountedAddonPrice(plan?.addons[0]?.price, plan?.addons[0]?.id) > 0 &&
                                  getDiscountedAddonPrice(plan?.addons[0]?.price, plan?.addons[0]?.id) !==
                                    plan?.addons[0]?.price && (
                                    <span className={`strikethrough mr-2 text-[#5C5C5C]`}>
                                      £{(plan?.addons[0]?.price / 100)?.toFixed(2)}
                                    </span>
                                  )}
                                <span
                                  className={`${
                                    getDiscountedAddonPrice(plan?.addons[0]?.price, plan?.addons[0]?.id) > 0
                                      ? 'ml-2'
                                      : ''
                                  } text-primary`}
                                >
                                  {getDiscountedAddonPrice(plan?.addons[0]?.price, plan?.addons[0]?.id) > 0
                                    ? `£${getDiscountedAddonPrice(
                                        plan?.addons[0]?.price / 100,
                                        plan?.addons[0]?.id
                                      )?.toFixed(2)}`
                                    : `£${(plan?.addons[0]?.price / 100)?.toFixed(2)}`}
                                </span>
                              </p>
                            )}

                            <div className="flex w-[200px] flex-col items-center gap-y-4">
                              {getAddonQuantity(plan?.addons[0]?.id) === 0 && (
                                <div className="min-h-[50px]">
                                  <button
                                    className="btn-cta !px-8 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                    disabled={disableAddonAddButton(plan?.addons[0]?.id, outOfStock)}
                                    onClick={() =>
                                      handleAddAddon(
                                        plan?.addons[0]?.id,
                                        plan?.addons[0]?.price,
                                        outOfStock,
                                        plan?.addons[0]?.name
                                      )
                                    }
                                  >
                                    {outOfStock ? 'Out of stock' : 'Add'}
                                  </button>
                                </div>
                              )}

                              {getAddonQuantity(plan?.addons[0]?.id) > 0 && (
                                <div>
                                  <div className="flex items-center justify-center">
                                    {getAddonQuantity(plan?.addons[0]?.id) > 0 && (
                                      <button
                                        className="btn-cta mr-4 h-10 w-10"
                                        type="button"
                                        onClick={() => handleRemoveAddon(plan?.addons[0]?.id)}
                                      >
                                        -
                                      </button>
                                    )}
                                    {getAddonQuantity(plan?.addons[0]?.id) > 0 && (
                                      <Field
                                        name="treats"
                                        type="text"
                                        placeholder="0"
                                        className="w-1/3 rounded-3xl border-[#D1D5DA] bg-checkoutBG py-3 px-4 text-center"
                                        value={getAddonQuantity(plan?.addons[0]?.id)}
                                        inputMode="none"
                                        onFocus={(e: any) => e.target.blur()}
                                      />
                                    )}
                                    {getAddonQuantity(plan?.addons[0]?.id) > 0 && (
                                      <button
                                        className="btn-cta ml-4 h-10 w-10 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                        type="button"
                                        disabled={disableAddonAddButton(plan?.addons[0]?.id, outOfStock)}
                                        onClick={() =>
                                          handleAddAddon(
                                            plan?.addons[0]?.id,
                                            plan?.addons[0]?.price,
                                            outOfStock,
                                            plan?.addons[0]?.name
                                          )
                                        }
                                      >
                                        +
                                      </button>
                                    )}
                                  </div>
                                </div>
                              )}

                              <button
                                className="btn-secondary mb-4 text-sm"
                                onClick={() => {
                                  setModalShow(true)
                                  setModalActiveRecipe(plan?.addons[0])
                                }}
                              >
                                Additional information
                              </button>
                            </div>
                          </div>

                          <div className="w-1/2 items-center justify-between gap-y-4 p-4 pt-0 md:ml-0 md:hidden md:pt-4">
                            <p className={`mb-2 ml-2 text-xl font-bold lg:text-2xl`}>
                              {getDiscountedAddonPrice(plan?.addons[0]?.price, plan?.addons[0]?.id) > 0 &&
                                getDiscountedAddonPrice(plan?.addons[0]?.price, plan?.addons[0]?.id) !==
                                  plan?.addons[0]?.price && (
                                  <span className={`strikethrough mr-2 text-[#5C5C5C]`}>
                                    £{(plan?.addons[0]?.price / 100)?.toFixed(2)}
                                  </span>
                                )}
                              <span
                                className={`${
                                  getDiscountedAddonPrice(plan?.addons[0]?.price, plan?.addons[0]?.id) > 0
                                    ? 'ml-2'
                                    : 'ml-10 lg:ml-0'
                                } text-primary`}
                              >
                                {getDiscountedAddonPrice(plan?.addons[0]?.price, plan?.addons[0]?.id) > 0
                                  ? `£${getDiscountedAddonPrice(
                                      plan?.addons[0]?.price / 100,
                                      plan?.addons[0]?.id
                                    )?.toFixed(2)}`
                                  : `£${(plan?.addons[0]?.price / 100)?.toFixed(2)}`}
                              </span>
                            </p>

                            <div className="flex w-[150px] flex-col items-center gap-y-4">
                              {getAddonQuantity(plan?.addons[0]?.id) === 0 && (
                                <div className="min-h-[50px]">
                                  <button
                                    className="btn-cta !px-8 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                    disabled={disableAddonAddButton(plan?.addons[0]?.id, outOfStock)}
                                    onClick={() =>
                                      handleAddAddon(
                                        plan?.addons[0]?.id,
                                        plan?.addons[0]?.price,
                                        outOfStock,
                                        plan?.addons[0]?.name
                                      )
                                    }
                                  >
                                    {outOfStock ? 'Out of stock' : 'Add'}
                                  </button>
                                </div>
                              )}

                              {getAddonQuantity(plan?.addons[0]?.id) > 0 && (
                                <div>
                                  <div className="flex items-center justify-center">
                                    {getAddonQuantity(plan?.addons[0]?.id) > 0 && (
                                      <button
                                        className="btn-cta mr-2 h-10 w-10"
                                        type="button"
                                        onClick={() => handleRemoveAddon(plan?.addons[0]?.id)}
                                      >
                                        -
                                      </button>
                                    )}
                                    {getAddonQuantity(plan?.addons[0]?.id) > 0 && (
                                      <Field
                                        name="treats"
                                        type="text"
                                        placeholder="0"
                                        className="w-1/3 rounded-3xl border-[#D1D5DA] bg-checkoutBG py-3 px-4 text-center"
                                        value={getAddonQuantity(plan?.addons[0]?.id)}
                                        inputMode="none"
                                        onFocus={(e: any) => e.target.blur()}
                                      />
                                    )}
                                    {getAddonQuantity(plan?.addons[0]?.id) > 0 && (
                                      <button
                                        className="btn-cta ml-4 h-10 w-10 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                        type="button"
                                        disabled={disableAddonAddButton(plan?.addons[0]?.id, outOfStock)}
                                        onClick={() =>
                                          handleAddAddon(
                                            plan?.addons[0]?.id,
                                            plan?.addons[0]?.price,
                                            outOfStock,
                                            plan?.addons[0]?.name
                                          )
                                        }
                                      >
                                        +
                                      </button>
                                    )}
                                  </div>
                                </div>
                              )}

                              <button
                                className="btn-secondary mx-auto text-xs sm:text-sm"
                                onClick={() => {
                                  setModalShow(true)
                                  setModalActiveRecipe(plan?.addons[0])
                                }}
                              >
                                Additional information
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="my-8 mx-auto py-10">
                          <div className="text-center">
                            <h3 className="mb-5 text-2xl sm:text-3xl">Our treats and extras</h3>
                          </div>
                          <ul className="grid grid-cols-2 gap-y-6 gap-x-2 sm:gap-x-8 lg:grid-cols-3 xl:grid-cols-4">
                            {plan &&
                              plan.addons &&
                              plan.addons.map(
                                (
                                  addon: {
                                    id: string
                                    name: string
                                    imageUrlNormal: string
                                    price: number
                                    itemId: string
                                    description: string
                                    status: string
                                  },
                                  i
                                ) => {
                                  const outOfStock = addon.status === 'OUTOFSTOCK'
                                  if (i === 0) return <React.Fragment key={`empty-${i}`}></React.Fragment>
                                  return (
                                    <li
                                      className={`relative mx-auto ml-[2%] -mr-[2%] w-full w-[96%] flex-row flex-nowrap overflow-hidden rounded-3xl bg-checkoutBG md:mr-0 md:ml-0 md:flex-col ${
                                        getAddonQuantity(addon?.id) > 0
                                          ? 'shadow-[0_0_0_4px_rgba(0,102,133,1)]'
                                          : 'shadow-[0_0_0_4px_rgba(209,213,218,0)]'
                                      }`}
                                      key={addon.id}
                                    >
                                      {addon?.isNew && (
                                        <span
                                          style={{ lineHeight: '1rem', right: '0.5rem' }}
                                          className="index-1 absolute -top-8 left-0 inline-flex h-24 w-24 -rotate-12 md:top-0 md:h-20 md:w-20 lg:h-20 lg:w-20 xl:inline-flex"
                                        >
                                          <img
                                            className="w-1/2 max-w-[75px] -rotate-12 object-contain md:w-full"
                                            src={NewSticker}
                                            alt="New"
                                          />
                                        </span>
                                      )}
                                      <div className="relative flex w-full flex-col pl-0 pt-0">
                                        <CloudinaryImage
                                          publicId={extractPublicId(addon?.images?.secondary)}
                                          alt={addon.name}
                                          steps={[350]}
                                          className="rounded-none object-cover object-top block max-h-[175px] xs:max-h-[190px] md:max-h-[255px] 2xl:max-h-[300px]"
                                        />
                                        {addon.limitedEdition && (
                                          <img
                                            className="absolute top-0 right-0 w-1/2"
                                            src={limitedEditionSticker}
                                            alt="Limited Edition"
                                          />
                                        )}
                                      </div>

                                      <div className="px-1 py-2 w-full sm:py-8 md:h-full md:w-full md:px-4">
                                        <div className="flex flex-col justify-between gap-y-4 text-left">
                                          <div>
                                            <h4 className="text-center text-sm text-primary sm:text-xl md:mb-4">
                                              {addon?.name}
                                            </h4>
                                            <div className="mb-4 flex flex-col">
                                              {addon?.highlights &&
                                                addon?.highlights?.map((highlight: any, i: number) => {
                                                  return (
                                                    <span
                                                      key={i}
                                                      className="mx-auto mt-1 justify-center rounded-full bg-[#FCD1CD] py-2 px-6 text-center text-xs xs:w-full md:text-sm"
                                                    >
                                                      {highlight}
                                                    </span>
                                                  )
                                                })}
                                            </div>
                                            <p className="mb-4 flex w-full items-center text-left text-xs text-black md:text-sm">
                                              {getPackSize(addon?.description)?.[0] ?? ''}
                                            </p>
                                            <div className="mb-4">
                                              {addon?.bullets && addon?.bullets?.length > 0 && (
                                                <ul className="mt-4 block w-full items-center justify-center gap-x-4 md:flex-row ">
                                                  {addon?.bullets?.map((bullet: string, i: number) => (
                                                    <li
                                                      key={i}
                                                      className="mx-1 inline-flex flex-1 items-center justify-center md:flex-initial"
                                                    >
                                                      <img width={20} src={BulletPointImage} alt={bullet} />
                                                      <span className="ml-2 text-xs md:text-sm">{bullet}</span>
                                                    </li>
                                                  ))}
                                                </ul>
                                              )}
                                            </div>
                                          </div>
                                          <div className="hidden flex-col text-center md:flex">
                                            <p className={`text-xl font-bold lg:text-2xl`}>
                                              {getDiscountedAddonPrice(addon.price, addon.id) > 0 &&
                                                getDiscountedAddonPrice(addon?.price, addon.id) !== addon.price && (
                                                  <span className="strikethrough mr-2 text-[#5C5C5C]">
                                                    £{(addon?.price / 100)?.toFixed(2)}
                                                  </span>
                                                )}
                                              <span
                                                className={`${
                                                  getDiscountedAddonPrice(addon.price, addon.id) > 0 ? 'ml-2' : ''
                                                } text-primary`}
                                              >
                                                {getDiscountedAddonPrice(addon.price, addon.id) > 0
                                                  ? `£${getDiscountedAddonPrice(addon.price / 100, addon.id)?.toFixed(
                                                      2
                                                    )}`
                                                  : `£${(addon?.price / 100)?.toFixed(2)}`}
                                              </span>
                                            </p>
                                            <div className="my-4 flex items-center justify-center">
                                              {getAddonQuantity(addon.id) > 0 && (
                                                <button
                                                  className="btn-cta mr-4 h-10 w-10"
                                                  type="button"
                                                  onClick={() => handleRemoveAddon(addon.id)}
                                                >
                                                  -
                                                </button>
                                              )}
                                              {getAddonQuantity(addon.id) > 0 && (
                                                <Field
                                                  name="treats"
                                                  type="text"
                                                  placeholder="0"
                                                  className="w-1/3 rounded-3xl border-[#D1D5DA] bg-checkoutBG py-3 px-4 text-center"
                                                  value={getAddonQuantity(addon.id)}
                                                  inputMode="none"
                                                  onFocus={(e: any) => e.target.blur()}
                                                />
                                              )}
                                              {getAddonQuantity(addon.id) > 0 && (
                                                <button
                                                  className="btn-cta ml-4 h-10 w-10 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                                  type="button"
                                                  disabled={disableAddonAddButton(addon.id, outOfStock)}
                                                  onClick={() =>
                                                    handleAddAddon(addon.id, addon.price, outOfStock, addon.name)
                                                  }
                                                >
                                                  +
                                                </button>
                                              )}
                                              {getAddonQuantity(addon.id) === 0 && (
                                                <div className="flex min-h-[50px] flex-col justify-center">
                                                  <button
                                                    type="button"
                                                    className={`btn-cta !px-8 !text-base !text-white disabled:cursor-not-allowed disabled:grayscale disabled:filter`}
                                                    disabled={disableAddonAddButton(addon.id, outOfStock)}
                                                    onClick={() =>
                                                      handleAddAddon(addon.id, addon.price, outOfStock, addon.name)
                                                    }
                                                  >
                                                    {outOfStock ? 'Out of stock' : 'Add'}
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                            <button
                                              className="btn-secondary mx-auto text-sm"
                                              onClick={() => {
                                                setModalShow(true)
                                                setModalActiveRecipe(addon)
                                              }}
                                            >
                                              Additional information
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="flex w-full flex-col items-center justify-between p-0 pt-0 md:hidden md:p-4 md:pt-4">
                                        <p className={`text-xl font-bold md:text-2xl`}>
                                          {getDiscountedAddonPrice(addon.price, addon.id) > 0 &&
                                            getDiscountedAddonPrice(addon.price, addon.id) !== addon.price && (
                                              <span className="strikethrough mr-2 text-[#5C5C5C]">
                                                £{(addon?.price / 100)?.toFixed(2)}
                                              </span>
                                            )}
                                          <span
                                            className={`${
                                              getDiscountedAddonPrice(addon.price, addon.id) > 0 ? 'ml-2' : ''
                                            } text-lg lg:text-primary`}
                                          >
                                            {getDiscountedAddonPrice(addon.price, addon.id) > 0
                                              ? `£${getDiscountedAddonPrice(addon.price / 100, addon.id)?.toFixed(2)}`
                                              : `£${(addon?.price / 100)?.toFixed(2)}`}
                                          </span>
                                        </p>
                                        <div className="my-4 flex items-center justify-center">
                                          {getAddonQuantity(addon.id) > 0 && (
                                            <button
                                              className="btn-cta mr-2 h-10 w-10 md:mr-4"
                                              type="button"
                                              onClick={() => handleRemoveAddon(addon.id)}
                                            >
                                              -
                                            </button>
                                          )}
                                          {getAddonQuantity(addon.id) > 0 && (
                                            <Field
                                              name="treats"
                                              type="text"
                                              placeholder="0"
                                              className="w-1/3 rounded-3xl border-[#D1D5DA] bg-checkoutBG py-3 px-2 text-center md:px-4"
                                              value={getAddonQuantity(addon.id)}
                                              inputMode="none"
                                              onFocus={(e: any) => e.target.blur()}
                                            />
                                          )}
                                          {getAddonQuantity(addon.id) > 0 && (
                                            <button
                                              className="btn-cta ml-2 h-10 w-10 disabled:cursor-not-allowed disabled:grayscale disabled:filter md:ml-4"
                                              type="button"
                                              disabled={disableAddonAddButton(addon.id, outOfStock)}
                                              onClick={() =>
                                                handleAddAddon(addon.id, addon.price, outOfStock, addon.name)
                                              }
                                            >
                                              +
                                            </button>
                                          )}
                                          {getAddonQuantity(addon.id) === 0 && (
                                            <div className="flex min-h-[50px] flex-col justify-center">
                                              <button
                                                type="button"
                                                className={`btn-cta !px-8 !text-base !text-white disabled:cursor-not-allowed disabled:grayscale disabled:filter`}
                                                disabled={disableAddonAddButton(addon.id, outOfStock)}
                                                onClick={() =>
                                                  handleAddAddon(addon.id, addon.price, outOfStock, addon.name)
                                                }
                                              >
                                                {outOfStock ? 'Out of stock' : 'Add'}
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                        <button
                                          className="btn-secondary mx-auto mb-2 text-xs sm:text-sm md:mb-0"
                                          onClick={() => {
                                            setModalShow(true)
                                            setModalActiveRecipe(addon)
                                          }}
                                        >
                                          Additional information
                                        </button>
                                      </div>
                                    </li>
                                  )
                                }
                              )}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                  {subscriptionId && (
                    <div className="relative p-4 md:p-8">
                      <div className="mb-4 w-full text-center font-serif">
                        <div className="mx-auto mb-8 max-w-xl text-2xl !leading-[1.3] md:text-4xl">
                          <p>Still got questions?</p>
                          <p>Get in touch with our team - they could talk dogs all day long</p>
                        </div>
                        <p className="text-base md:text-xl">
                          Call us on{' '}
                          <a className="font-bold" href="tel:+441743384562">
                            01743 384 562
                          </a>{' '}
                          or email{' '}
                          <a className="font-bold" href="mailto:feedme@differentdog.com">
                            feedme@differentdog.com
                          </a>{' '}
                        </p>
                      </div>
                      <div className="mt-10 hidden sm:block">
                        <TrustPilotMicroCombo backgroundColor="transparent" />
                      </div>
                      <div className="mt-5 -mb-8 block sm:hidden">
                        <TrustPilotMini backgroundColor="transparent" />
                      </div>
                      <div className="mx-auto w-full md:w-4/5">
                        <TrustBoxAlternate />
                      </div>
                    </div>
                  )}
                  <div className="flex items-center justify-center">
                    <StaticImage
                      className="my-5 flex"
                      src="./../../../images/builder/differentDog-logo.png"
                      alt="Different Dog logo"
                    />
                  </div>

                  <StickyFooter className="fixed bottom-0 left-0 right-0 z-10 ml-auto max-h-20 bg-white shadow-[0_-5px_15px_rgba(0,0,0,0.25)] sm:w-[calc(102%-12rem)]">
                    <div className="w-full">
                      <div className="relative top-8 z-10 w-full text-center text-primary md:-ml-12 xl:top-4">
                        <p>
                          Treats selected: <span className="font-bold">{addonCount}</span>
                        </p>
                      </div>
                      <div className="relative -top-16 flex w-full items-center justify-between">
                        <button
                          data-testid="treatsBack"
                          type="button"
                          onClick={backClick}
                          className="rounded-full border border-primary bg-white px-4 py-2 shadow-md hover:underline focus:underline"
                        >
                          Back
                        </button>
                        <button
                          data-testid="treatsNext"
                          disabled={submitting}
                          onClick={() => props.handleSubmit()}
                          type="button"
                          className={`btn-cta scale-animation !px-8 !py-4 !text-xl !font-bold shadow-[0_-5px_15px_rgba(0,0,0,0.25)] sm:!px-16`}
                        >
                          {submitting ? (
                            <div className="align-center flex justify-center">
                              <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                              <div>Saving</div>
                            </div>
                          ) : (
                            'Confirm'
                          )}
                        </button>
                      </div>
                    </div>
                  </StickyFooter>
                </div>
              )
            }}
          </Formik>
        </div>
        <RecipeOrTreatAlterModal show={modalShow} setShow={setModalShow} activeRecipe={modalActiveRecipe} />
        <AddTreatConfirmModal show={addTreatModalShow} setShow={setAddTreatModalShow} treatsAdded={addons} />
        <SelectPlanAddTreatModal
          show={showSelectPlanModal}
          setShow={setShowSelectPlanModal}
          plans={activePlans}
          setSelectedPlan={setSelectedPlan}
        />
        {errorMessage && (
          <ErrorModal
            show={showPopup}
            setShow={setShowPopup}
            errorMessage={errorMessage?.popup?.message ?? errorMessage?.message}
            url={errorMessage?.popup?.url ?? errorMessage?.url}
            action={errorMessage?.popup?.action ?? errorMessage?.action}
            actionText={errorMessage?.popup?.actionText ?? errorMessage?.actionText}
          />
        )}
      </>
    )
  )
}
export default AddTreats
