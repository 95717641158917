import FeedbackForm from 'components/ui/feedback-form/FeedbackForm'
import DogsModal from 'components/ui/modal/DogsModal'
import ErrorModal from 'components/ui/modal/ErrorModal'
import Toast from 'components/ui/toast/Toast'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Field, Form, Formik } from 'formik'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { RootState } from 'state'
import { deleteDog, updateDogs, updateDogsToDelete, updatePlanRadio } from 'state/actions/account/accountDogAction'
import { updateOrders, updatePlans as updatePlansFromPlanAction } from 'state/actions/account/accountPlanAction'
import { createDogs, createDogsExistingPlan, resetFlowDogs } from 'state/actions/account/flow/dogAction'
import { resetFlowPlan } from 'state/actions/account/flow/planAction'
import { resetFlowShipping } from 'state/actions/account/flow/shippingAction'
import { resetKey } from 'state/actions/keyAction'
import Api from 'state/apis/api'
import { DogType } from 'state/types/account/accountDogType'
import * as yup from 'yup'
import loadingGIF from '../../../../images/loadingspinner.gif'
import EditFutureOrderModal from '../../../ui/modal/EditFutureOrderModal'
import { months } from './data'
import ProfilePicture from './profilePicture'

dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)

const Dogs: React.FC = () => {
  const { dogs, planRadio, loading }: { dogs: DogType[]; plans: []; planRadio: string; loading: boolean } = useSelector(
    (state: RootState) => state.accountDog
  )
  const { plans } = useSelector((state: RootState) => state.accountPlan)

  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const { changed, error } = useSelector((state: RootState) => state.accountDogFlow)
  const [modalShow, setModalShow] = useState(false)
  const [modalText, setModalText] = useState(<></>)
  const [modalButtons, setModalButtons] = useState(<></>)
  const [redZoneModalShow, setRedZoneModalShow] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [_radio, _setRadio] = useState('newPlan')
  const [redZoneConfirmButton, setRedZoneConfirmButton] = useState(<></>)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [formDisabled, setFormDisabled] = useState(true)

  const [zendeskScript, setZendeskScript] = useState(null)
  const [showToast, setShowToast] = useState(false)
  const [toastMsg, setToastMsg] = useState('')
  const [breeds, setBreeds] = useState([])
  const [browser, setBrowser] = useState('')
  const [profilePictureUploaded, setProfilePictureUploaded] = useState(false)
  const [years, setYears] = useState<number[]>([])
  const todayDate = dayjs().format('DD/MM/YYYY')
  const currentDate = todayDate.split('/')

  useEffect(() => {
    const yearsArray = []
    for (let year = new Date().getFullYear(); year >= 2000; year--) {
      yearsArray.push(year)
    }
    setYears(yearsArray)
  }, [])

  useEffect(() => {
    if (typeof window['safari'] !== 'undefined') {
      setBrowser('Safari')
    }
    if (window.navigator && typeof window.navigator !== 'undefined') {
      if (window.navigator.userAgent.includes('iPhone')) {
        setBrowser('Safari')
      }
    }
  }, [])

  useEffect(() => {
    if (error) {
      setShowPopup(true)
    }
  }, [error])

  useEffect(() => {
    callDogs()

    dispatch(updatePlansFromPlanAction())
    dispatch(updateOrders())
    dispatch(resetFlowDogs())
    dispatch(resetFlowPlan())
    dispatch(resetFlowShipping())
    dispatch(resetKey())
    dispatch(updateDogsToDelete([]))
  }, [])

  useEffect(() => {
    if (changed) {
      const url = spoofMode ? '/account/plan-recipes/?userId=' + spoofedUserId : '/account/plan-recipes/'
      navigate(url)
    }
  }, [changed])

  useEffect(() => {
    if (dogs?.length === 1 && window.location.pathname.includes('edit')) {
      setFormDisabled(false)
    }
  }, [])

  useEffect(() => {
    const zendeskScript = (
      <script type="text/javascript">{`
        if (typeof window.zE !== 'undefined') {
          window.zE('webWidget', 'helpCenter:setSuggestions', { search: 'details for my dog' })
        }
    `}</script>
    )
    // This is a hack because zendesk takes a long time to load onto the page
    window.setTimeout(() => {
      setZendeskScript(zendeskScript)
    }, 1000)
  }, [])

  useEffect(() => {
    callBreeds()
  }, [])

  const callBreeds = () => {
    const url = spoofMode
      ? '/v1/private/admin/account/dog/breeds?userId=' + spoofedUserId
      : '/v1/private/account/dog/breeds'
    Api.get('wwwREST', url, {})
      .then(({ breedsList }) => {
        setBreeds(breedsList)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const callDogs = () => {
    const url = spoofMode ? '/v1/private/admin/account/dogs?userId=' + spoofedUserId : '/v1/private/account/dogs'
    setIsLoading(true)
    Api.get('wwwREST', url, {})
      .then(({ dogs }) => {
        dispatch(updateDogs(dogs))
        setIsLoading(false)
        setErrorMessage('')
        setShowPopup(false)
      })
      .catch(err => {
        console.log(err)
        if (err.response.status === 503) {
          navigate('/maintenance')
        }
        setIsLoading(false)
        setShowPopup(true)
        setErrorMessage('Something has gone wrong.')
      })
  }

  const validationSchema = Object.assign({
    name: yup.string().trim().required('Name is required'),
    gender: yup.string().required('Sex is Required'),
    breed: yup
      .array()
      .max(2, 'Max of 2 breeds')
      .of(
        yup.object().shape({
          label: yup.string().required('Breed is Required'),
          value: yup.string().required('Breed is Required')
        })
      ),
    dobYear: yup.string().required('Year of Date of Birth is Required'),
    dobMonth: yup.string(),
    dobDay: yup.string(),
    exercise: yup.string().required('Exercise amount is required'),
    fussy: yup.string().required('Eating preference is required'),
    id: yup.string(),
    medicalConditionOrAllergies: yup.string().required('Medical Condition or Allergies is required'),
    neutered: yup.string().required('Neutered/Spayed is required'),
    physique: yup.string().required('Physique is required'),
    weight: yup
      .number()
      .min(1, 'Weight must be above 1kg')
      .max(75, 'Weight must be below 75kg')
      .required('Weight is required'),
    newDog: yup.boolean()
  })

  const handleAddNewDog = () => {
    const newDog: DogType = {
      name: '',
      dob: '',
      exercise: '',
      gender: '',
      breed: '',
      id: '',
      medicalConditionOrAllergies: '',
      fussy: '',
      neutered: '',
      physique: '',
      weight: undefined,
      newDog: true
    }
    let tempDogs = dogs || []
    tempDogs.unshift(newDog)

    dispatch(updateDogs(tempDogs))
  }

  const handleCancelDog = (value, i) => {
    if (!value.values.id) {
      const updateDog = dogs.filter((_item, index) => index !== i)
      dispatch(updateDogs(updateDog))
    }
  }

  const getDogDob = (values: any) => {
    if (values.dobMonth && !values.dobDay) {
      return dayjs(values.dobYear + '/' + values.dobMonth).format('MMMM YYYY')
    }

    if (values.dobMonth && values.dobDay) {
      return dayjs(values.dobYear + '/' + values.dobMonth + '/' + values.dobDay).format('Do MMMM YYYY')
    }

    return values.dobYear
  }

  const formatDogDob = (year: string, month: string, day: string) => {
    if (month && day) {
      return `${year}-${month}-${day}`
    }

    if (month && !day) {
      return `${year}-${month}`
    }

    return year
  }

  const checkIfPuppy = (dob: string) => {
    const todayMinus1Year = dayjs().subtract(1, 'year')
    const dogDob = dayjs(dob)
    return todayMinus1Year.diff(dogDob, 'year', true) <= 0
  }

  const addDogToPlan = (formikValues: any, values: any) => {
    if (formikValues.planRadio === 'newPlan') {
      let selectedBreeds = values.breed?.map(b => b.value)
      dispatch(
        createDogs({
          dogs: [
            {
              name: values.name,
              weight: values.weight,
              dob: formatDogDob(values.dobYear, values.dobMonth, values.dobDay),
              exercise: values.exercise,
              physique: values.physique,
              neutered: JSON.parse(values.neutered),
              gender: values.gender,
              breed: selectedBreeds,
              medicalConditionOrAllergies: JSON.parse(values.medicalConditionOrAllergies),
              fussy: values.fussy
            }
          ]
        })
      )
    } else {
      let selectedBreeds = values.breed?.map(b => b.value)
      dispatch(updatePlanRadio(formikValues.planRadio))
      dispatch(
        createDogsExistingPlan({
          subscriptionId: formikValues.planRadio,
          dogs: [
            {
              name: values.name,
              weight: values.weight,
              dob: formatDogDob(values.dobYear, values.dobMonth, values.dobDay),
              exercise: values.exercise,
              physique: values.physique,
              neutered: JSON.parse(values.neutered),
              gender: values.gender,
              breed: selectedBreeds,
              medicalConditionOrAllergies: JSON.parse(values.medicalConditionOrAllergies),
              fussy: values.fussy
            }
          ]
        })
      )
    }
  }

  const getMissingDetailsFromDogs = () => {
    const allMissingDataForDogs: any = []

    if (dogs) {
      dogs.map(dog => {
        const missingDataForDog = []

        if (!dog.newDog) {
          for (const [key, value] of Object.entries(dog)) {
            if (value === null || value === '') {
              if (key === 'gramsPerDay') {
                missingDataForDog.push('grams per day')
              } else if (key === 'dob') {
                missingDataForDog.push('date of birth')
              } else if (key === 'percentageOfDiet') {
                missingDataForDog.push('percentage of diet')
              } else if (key === 'kcalPerDay') {
                missingDataForDog.push('kcals per day')
              } else if (key === 'medicalConditionOrAllergies') {
                missingDataForDog.push('medical conditions or allergies')
              } else if (key === 'neutered') {
                missingDataForDog.push('neutered')
              } else {
                missingDataForDog.push(key)
              }
            }
          }
        }

        if (missingDataForDog?.length > 0) {
          allMissingDataForDogs.push({ name: dog.name, keys: missingDataForDog })
        }
      })
    }

    return allMissingDataForDogs
  }

  const dogsHaveRequiredDataForFeedingGuide = () => {
    let requiredData = true

    dogs.map(d => {
      if (typeof d.percentageOfDiet === 'undefined' || !d.percentageOfDiet) {
        requiredData = false
      }

      if (typeof d.gramsPerDay === 'undefined' || !d.gramsPerDay) {
        requiredData = false
      }
    })

    return requiredData
  }

  const newDogFormOpen = () => {
    if (dogs && dogs?.length > 0) {
      const filteredDogs = dogs.filter(dog => dog.newDog)
      return filteredDogs?.length > 0
    }

    return false
  }

  if (isLoading) {
    return (
      <div className="m-auto w-full text-center" style={{ marginTop: '25vh' }}>
        <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
      </div>
    )
  }

  const handleSetShowToast = (show: boolean) => {
    setShowToast(show)
  }

  const roundToNearest5Percent = (digit: number) => {
    return Math.round(digit / 5) * 5
  }

  const getGramsPerDay = (p: any, d: any) => {
    if (d.gramsPerDay) {
      if (d.percentageOfDiet && d.percentageOfDiet > 100) {
        return d.gramsPerDay
      } else {
        return roundToNearest5Percent((d.percentageOfDiet / 100) * d.gramsPerDay)
      }
    }
    const packSize = p.plan.replace(/[^0-9 ]/g, '')
    const gramsPerDay = roundToNearest5Percent((packSize * p.quantity) / (p.weeks * 7))
    if (roundToNearest5Percent((gramsPerDay / p.dogs[0]?.gramsPerDay) * 100) > 100) {
      return p.dogs[0]?.gramsPerDay
    } else {
      return roundToNearest5Percent((packSize * p.quantity) / (p.weeks * 7))
    }
  }

  const getCustomPercentage = (p: any) => {
    let customPercentage = 0
    p.dogs.forEach(dog => {
      customPercentage += dog.percentageOfDiet
    })
    customPercentage = roundToNearest5Percent(customPercentage / (p.dogs.length || 1))
    if (customPercentage > 100) {
      return 100
    } else {
      return customPercentage
    }
  }

  const getPercentageOfPack = (p: any, d: any) => {
    const packSize = p.plan.replace(/[^0-9 ]/g, '')
    const grams = getGramsPerDay(p, d)
    return Math.round(((grams / packSize) * 100) / 5) * 5
  }
  const getExactPercentageOfPack = (p: any, d: any) => {
    const packSize = p.plan.replace(/[^0-9 ]/g, '')
    const grams = getGramsPerDay(p, d)
    return Math.round((grams / packSize) * 100)
  }

  const getPacksPerDay = (p: any) => {
    let percentageOfPack = 0
    p.dogs.forEach(dog => {
      percentageOfPack += getExactPercentageOfPack(p, dog)
    })
    return Math.floor(100 / percentageOfPack / 0.5) * 0.5
  }

  const getPackSize = (p: any) => {
    return p.plan
  }

  return (
    !isLoading && (
      <div className="min-h-screen">
        <div className="flex flex-1 flex-col">
          <main>
            <h2 data-testid="dogProfileAndFeedingGuide" className="mt-8 text-center text-3xl sm:text-5xl">
              Dog Profile<span className="mt-2 block text-3xl">&amp; Feeding Guide</span>
            </h2>
            {dogs?.length === 0 && (
              <>
                <div className="mt-4 rounded-xl border border-ddCustomiseDark bg-beigeBG p-4">
                  <h3 className="mb-4 text-2xl">No active dogs</h3>
                  <div className="leading-7">
                    <p>
                      Please first check your payment details are up to date. Following this, click Add new dog and
                      complete the steps to set up a new plan.
                    </p>
                    <p>Please contact customer delight if you have any issues.</p>
                  </div>
                </div>
              </>
            )}

            {getMissingDetailsFromDogs().length > 0 ? (
              <div className="relative mt-4 rounded-xl border border-ddCustomise bg-ddYellow p-4 pl-16">
                <StaticImage
                  width={64}
                  className="absolute -top-2 -left-2 md:-left-4 md:-top-4"
                  src="../../../../images/caution.svg"
                  alt="Delivery in hot weather?"
                  imgStyle={{ position: 'absolute' }}
                  style={{ position: 'absolute' }}
                />
                {getMissingDetailsFromDogs().map((dog: any) => {
                  return (
                    <p
                      className={`text-center ${
                        getMissingDetailsFromDogs().length > 1 && 'border-b border-ddCustomise'
                      }`}
                    >{`${dog.name} is missing their ${dog.keys
                      .join(', ')
                      .replace(/,(?!.*,)/gim, ' &')} information`}</p>
                  )
                })}
                <p className="text-center">Please update below</p>
              </div>
            ) : (
              ''
            )}

            {plans?.length > 0 && (
              <div className="mx-auto mt-4 w-full rounded-3xl bg-ddBlue p-8 md:w-1/2 md:min-w-[500px]">
                <h3 data-testid="weAddedAFeedingGuide" className="mb-4 text-xl md:text-2xl">
                  We added a feeding guide for you!
                </h3>
                <p>
                  Below your dog's profile you can find our new customised Feeding guide which may help you feed your
                  dog the right amount of food for their diet.
                </p>
              </div>
            )}

            <div className="mt-8 flex justify-center">
              {dogs && dogs.length < 5 && (
                <button
                  className={`flex flex-row items-center justify-center ${
                    newDogFormOpen() ? 'cursor-not-allowed opacity-40' : 'group'
                  }`}
                  data-testid="addNewDog"
                  onClick={() => {
                    if (!newDogFormOpen()) {
                      handleAddNewDog()
                    }
                  }}
                >
                  <span
                    style={{ fontSize: '4rem' }}
                    className="mr-4 inline-flex h-12 w-12 items-center justify-center rounded-full border border-2 border-primary"
                  >
                    +
                  </span>
                  <span className="group-hover:underline">Add new dog</span>
                </button>
              )}
            </div>
            {!profilePictureUploaded && (
              <div className="rounded-3xl px-0 mx-auto w-full relative md:w-1/2 bg-[#C6DABB] mt-4">
                <div className="p-2 flex flex-row items-center justify-center">
                  <p className="text-center text-xs md:text-base font-sans">
                    <span className="font-bold">New feature! </span>
                    Personalise your dog's profile with a picture
                  </p>
                </div>
              </div>
            )}
            <div className={`-mr-4 mt-4 flex flex-row flex-wrap ${dogs?.length === 1 ? 'justify-center' : ''}`}>
              {dogs &&
                dogs.length > 0 &&
                dogs.map((dog: DogType, i) => {
                  return (
                    <Formik
                      key={dog.id}
                      initialValues={{
                        name: dog.name,
                        gender: dog.gender || '',
                        breed: dog.breed !== '' && dog.breed !== undefined ? dog.breed : null,
                        dobDay: typeof dog.dob === 'string' && dog.dob.split('-')[2] ? dog.dob.split('-')[2] : '',
                        dobMonth: typeof dog.dob === 'string' && dog.dob.split('-')[1] ? dog.dob.split('-')[1] : '',
                        dobYear: typeof dog.dob === 'string' && dog.dob.split('-')[0] ? dog.dob.split('-')[0] : '',
                        physique: dog.physique,
                        medicalConditionOrAllergies:
                          typeof dog.medicalConditionOrAllergies === 'boolean'
                            ? dog.medicalConditionOrAllergies.toString()
                            : '',
                        neutered: typeof dog.neutered === 'boolean' ? dog.neutered.toString() : '',
                        weight: dog.weight,
                        exercise: dog.exercise,
                        fussy: typeof dog.fussy === 'string' ? dog.fussy : '',
                        id: dog.id,
                        newDog: dog.newDog ? dog.newDog : false
                      }}
                      validationSchema={yup.object().shape(validationSchema)}
                      validateOnChange
                      onSubmit={values => {
                        if (values.newDog) {
                          if (dogs.length > 1) {
                            setModalText(<h3>Do you want to add your dog to an existing or a new plan?</h3>)
                            setModalShow(true)

                            setModalButtons(
                              <Formik
                                initialValues={{
                                  // @ts-ignore
                                  planRadio: ''
                                }}
                                validationSchema={yup.object().shape({
                                  planRadio: yup.string().required('Please Select a Plan')
                                })}
                                onSubmit={formikValues => {
                                  let inRedZone = false

                                  {
                                    plans.map((plan: any) => {
                                      if (formikValues.planRadio === plan.subscriptionId) {
                                        if (plan.redZone) {
                                          inRedZone = true
                                        }
                                      }
                                    })
                                  }

                                  dispatch(updatePlanRadio(formikValues.planRadio))

                                  if (!inRedZone) {
                                    addDogToPlan(formikValues, values)
                                  } else {
                                    setRedZoneConfirmButton(
                                      <button
                                        type="button"
                                        className="btn-cta inline-flex justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
                                        onClick={() => {
                                          setRedZoneModalShow(false)
                                          addDogToPlan(formikValues, values)
                                        }}
                                      >
                                        Edit plan
                                      </button>
                                    )
                                    setModalShow(false)
                                    setRedZoneModalShow(true)
                                  }
                                }}
                              >
                                {props => {
                                  return (
                                    <Form className="col-span-2 w-full">
                                      <div className="col-span-2 mx-auto flex max-w-[500px] flex-col">
                                        {plans.map((plan: any) => {
                                          return (
                                            <div className="mb-4" key={plan.dogNames}>
                                              <label>
                                                <Field
                                                  value={plan.subscriptionId}
                                                  type="radio"
                                                  id={`${plan.subscriptionId}`}
                                                  name="planRadio"
                                                  className="text-base focus:ring-primary"
                                                />
                                                <span className="ml-4">
                                                  {plan.dogNames ? `${plan.dogNames}'s plan` : 'Plan'}
                                                </span>
                                              </label>
                                            </div>
                                          )
                                        })}
                                        <div className="mb-4">
                                          <label>
                                            <Field
                                              value="newPlan"
                                              type="radio"
                                              id="newPlan"
                                              name="planRadio"
                                              className="text-base focus:ring-primary"
                                            />
                                            <span className="ml-4">
                                              Create a new plan
                                              <span className="ml-8 mt-1 block text-xs">
                                                (Create a new plan if you want food for this dog to go to a different
                                                address)
                                              </span>
                                            </span>
                                          </label>
                                        </div>
                                        {props.errors.planRadio && (
                                          <div className="mb-4 p-2 text-xs text-red-500">{props.errors.planRadio}</div>
                                        )}
                                      </div>
                                      <div className="mx-auto grid grid-cols-3">
                                        <div className="col-start-2 inline-flex justify-center">
                                          <button
                                            type="button"
                                            className="btn-secondary"
                                            onClick={() => {
                                              setModalShow(false)
                                            }}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                        <div className="col-start-3 flex justify-end">
                                          <button
                                            data-testid="confirm"
                                            type="submit"
                                            disabled={!props.isValid || !props.values.planRadio}
                                            className="btn-cta inline-flex justify-center disabled:cursor-not-allowed disabled:bg-[#B0CDD5]"
                                          >
                                            Next
                                          </button>
                                        </div>
                                      </div>
                                    </Form>
                                  )
                                }}
                              </Formik>
                            )
                          } else {
                            dispatch(updatePlanRadio('newPlan'))
                            let selectedBreeds = values.breed?.map(b => b.value)
                            dispatch(
                              createDogs({
                                dogs: [
                                  {
                                    name: values.name,
                                    weight: values.weight,
                                    dob: formatDogDob(values.dobYear, values.dobMonth, values.dobDay),
                                    exercise: values.exercise,
                                    physique: values.physique,
                                    neutered: JSON.parse(values.neutered),
                                    gender: values.gender,
                                    breed: selectedBreeds,
                                    medicalConditionOrAllergies: JSON.parse(values.medicalConditionOrAllergies),
                                    fussy: values.fussy
                                  }
                                ]
                              })
                            )
                          }
                        } else {
                          let selectedBreeds = null
                          if (values.breed?.length > 0) selectedBreeds = values.breed?.map(b => b.value)
                          const url = spoofMode
                            ? `/v1/private/admin/account/dog/${values.id}?userId=${spoofedUserId}`
                            : `/v1/private/account/dog/${values.id}`
                          Api.put('wwwREST', url, {
                            body: {
                              name: values.name,
                              weight: values.weight,
                              dob: formatDogDob(values.dobYear, values.dobMonth, values.dobDay),
                              exercise: values.exercise,
                              physique: values.physique,
                              neutered: JSON.parse(values.neutered),
                              gender: values.gender,
                              breed: selectedBreeds,
                              medicalConditionOrAllergies: JSON.parse(values.medicalConditionOrAllergies),
                              fussy: values.fussy
                            }
                          })
                            .then(({ dogs }) => {
                              setErrorMessage('')
                              setShowToast(true)
                              setToastMsg('Dog details updated successfully')
                              setShowPopup(false)
                              callDogs()
                              setFormDisabled(true)

                              let _dogNames = ''
                              let subscriptionId = ''
                              let amountOfDogs = 0

                              // Get subscriptionID and dognames
                              plans.map(plan => {
                                plan.dogs.map(dog => {
                                  if (dog.id === values.id) {
                                    _dogNames = plan.dogNames
                                    subscriptionId = plan.subscriptionId
                                  }
                                  amountOfDogs++
                                })
                              })

                              if (subscriptionId) {
                                setModalText(
                                  <div className="m-2 grid grid-cols-1 gap-3">
                                    <span className="font-serif">
                                      {amountOfDogs === 1
                                        ? `${values.name}'s details have now been updated.`
                                        : `${values.name}'s details have now been updated.`}
                                      <br />
                                      Would you like to update your plan?
                                    </span>
                                    <span className="font-sans">
                                      As your dog's details have changed, you may need to adjust their plan to ensure
                                      they are still getting an appropriate daily serving.
                                    </span>
                                  </div>
                                )
                                setModalShow(true)

                                // Check if dog subscription is in redZone
                                const plan = plans?.filter(plan => plan.subscriptionId === dogs[0].subscription.id)
                                const redZone = plan[0]?.redZone || false
                                setModalButtons(
                                  <div className="mx-auto grid w-full grid-cols-2">
                                    <div className="inline-flex justify-center">
                                      <button
                                        data-testid="noUpdatePlanButton"
                                        type="button"
                                        className="inline-flex items-center border-b-2 border-primary hover:font-bold focus:outline-gray-500 disabled:border-gray-500"
                                        onClick={() => {
                                          setModalShow(false)
                                        }}
                                      >
                                        No
                                      </button>
                                    </div>
                                    <div className="inline-flex justify-center">
                                      <button
                                        type="button"
                                        className="btn-cta"
                                        onClick={() => {
                                          if (redZone) {
                                            setRedZoneConfirmButton(
                                              <button
                                                data-testid="editFuturePlan"
                                                type="button"
                                                className="btn-cta inline-flex justify-center px-4 py-2"
                                                onClick={() => {
                                                  setRedZoneModalShow(false)
                                                  dispatch(updatePlanRadio(subscriptionId))
                                                  dispatch(
                                                    createDogsExistingPlan({
                                                      subscriptionId: subscriptionId,
                                                      dogs: dogs
                                                    })
                                                  )
                                                }}
                                              >
                                                Edit plan
                                              </button>
                                            )
                                            setRedZoneModalShow(true)
                                          } else {
                                            dispatch(updatePlanRadio(subscriptionId))
                                            dispatch(
                                              createDogsExistingPlan({
                                                subscriptionId: subscriptionId,
                                                dogs: dogs
                                              })
                                            )
                                          }
                                        }}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                  </div>
                                )
                              }
                            })
                            .catch(err => {
                              console.log(err)
                              if (err.response.status === 503) {
                                navigate('/maintenance')
                              }
                              setErrorMessage('Something has gone wrong.')
                              setShowPopup(true)
                            })
                        }
                      }}
                    >
                      {props => {
                        const [disabled, setDisabled] = useState(
                          formDisabled === false ? false : props.values.newDog ? false : true
                        )
                        function checkInputState(fieldName: string) {
                          if (
                            fieldName === 'breed' &&
                            props.values[fieldName] &&
                            props.values[fieldName].length === 0
                          ) {
                            return 'field-error'
                          }
                          if (fieldName === 'fussy' && !props.values[fieldName] && !dog?.newDog) {
                            return 'field-error'
                          }
                          if (!props.errors[fieldName] && props.values[fieldName]) {
                            return 'field-success'
                          } else if (props.errors?.[fieldName] && props.touched?.[fieldName]) {
                            return 'field-error'
                          }
                          return 'field-focus'
                        }

                        return (
                          <Form className="w-full pr-4 lg:w-1/2">
                            <div className="w-full">
                              <div
                                className={`${
                                  disabled ? 'p-4' : ''
                                } relative my-4 grid w-full grid-cols-1 gap-y-2 rounded-3xl bg-beigeBG px-4 py-4 sm:grid-cols-1 md:px-8 md:py-8`}
                              >
                                <div className={`flex flex-row ${disabled ? 'mb-2' : 'mb-6'}`}>
                                  {!props.values.newDog && (
                                    <ProfilePicture
                                      dogId={dog.id}
                                      dogView={disabled}
                                      setProfilePicUploaded={setProfilePictureUploaded}
                                    />
                                  )}
                                  <div className="flex flex-col relative pl-1 justify-center items-start">
                                    {!disabled && !props.values.newDog && (
                                      <h4 className="mt-8 relative text-xl md:text-3xl">
                                        Editing {dog.name}'s details
                                      </h4>
                                    )}
                                    {disabled && (
                                      <>
                                        <h4 data-testid="name" className="mt-4 relative mb-2 text-xl md:text-3xl">
                                          {dog.name}
                                        </h4>
                                        {dog.gramsPerDay && (
                                          <span>
                                            Needs <span className="font-bold">{dog.gramsPerDay + 'g'}</span> daily
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div role="group" aria-labelledby="name-group" className="col-span-2 flex flex-col">
                                  {!disabled && props.values.newDog && (
                                    <h4 className="mb-4 text-xl md:text-3xl">New dog details</h4>
                                  )}
                                  {!disabled && (
                                    <div className="relative flex w-full cursor-text flex-row items-center font-medium">
                                      <Field
                                        data-testid="name"
                                        type="text"
                                        name="name"
                                        onChange={(e: any) => {
                                          props.setFieldValue(
                                            'name',
                                            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                                          )
                                        }}
                                        className={`${disabled ? 'h3' : 'pt-6'} ${checkInputState('name')} ${
                                          browser === 'Safari' ? 'text-input' : 'text-base'
                                        } peer w-full cursor-text rounded-md border border-gray-300 bg-white focus:border-secondary focus:ring-secondary disabled:mb-2 disabled:w-auto disabled:border-0 disabled:bg-transparent disabled:bg-none disabled:px-0 disabled:text-xl disabled:font-bold disabled:opacity-100 disabled:md:text-3xl`}
                                        disabled={disabled}
                                      />
                                      <label
                                        htmlFor="name"
                                        className={`absolute ${
                                          disabled && 'hidden'
                                        } top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                          props.values.name ? 'ml-0 -translate-y-3 scale-75' : 'ml-4'
                                        }`}
                                      >
                                        Dog's name<span className="ml-1">*</span>
                                      </label>
                                    </div>
                                  )}
                                  {props.errors.name && props.touched.name ? (
                                    <div className="p-2 text-xs text-red-500">{props.errors.name}</div>
                                  ) : null}
                                </div>
                                <Field type="hidden" name="id" />
                                <div
                                  role="group"
                                  aria-labelledby="gender-group"
                                  className="col-span-2 flex flex-col py-2"
                                >
                                  <div className="relative flex w-full cursor-text flex-row items-center font-medium">
                                    {disabled && <span>Sex:</span>}
                                    <span className="sr-only">Sex</span>
                                    <Field
                                      data-testid="gender"
                                      as="select"
                                      name="gender"
                                      className={`${checkInputState(
                                        'gender'
                                      )} peer w-full cursor-text rounded-md border border-gray-300 bg-white ${
                                        props.values.gender ? 'p-2 px-3.5 pt-6' : 'p-4'
                                      } focus:border-secondary focus:ring-secondary disabled:ml-2 disabled:w-auto disabled:border-0 disabled:bg-transparent disabled:bg-none disabled:p-0 disabled:font-bold disabled:opacity-100`}
                                      disabled={disabled}
                                    >
                                      <option disabled value="">
                                        {dog?.newDog ? 'Sex *' : '-'}
                                      </option>
                                      <option value="MALE">Male</option>
                                      <option value="FEMALE">Female</option>
                                    </Field>
                                    <label
                                      htmlFor="gender"
                                      className={`absolute ${
                                        disabled && 'hidden'
                                      } top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-2.5 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                        props.values.gender ? 'ml-2.5 -translate-y-3 scale-75' : 'hidden'
                                      }`}
                                    >
                                      Sex<span className="ml-1">*</span>
                                    </label>
                                  </div>
                                  {props.errors.gender && props.touched.gender ? (
                                    <div className="p-2 text-xs text-red-500">{props.errors.gender}</div>
                                  ) : null}
                                </div>
                                {disabled && (
                                  <div className="">
                                    <span>Breed: </span>
                                    {!dog.breed?.length && <span className="p-2 font-bold">-</span>}
                                    {dog.breed && dog?.breed?.length === 1 && (
                                      <span className="p-2 font-bold">{dog?.breed[0].label}</span>
                                    )}{' '}
                                    {dog.breed &&
                                      dog?.breed?.length > 1 &&
                                      dog.breed.map((b, index) => (
                                        <span className="pl-2 font-bold">{(index ? '/ ' : '') + b.label}</span>
                                      ))}
                                  </div>
                                )}
                                {!disabled && (
                                  <div
                                    role="group"
                                    aria-labelledby="breed-group"
                                    className="col-span-2 flex flex-col py-2"
                                  >
                                    <div className="relative flex w-full cursor-text flex-row items-center font-medium">
                                      <span className="sr-only">Breed</span>

                                      {!disabled && (
                                        <Select
                                          inputId="breedTestId"
                                          className={`${checkInputState(
                                            'breed'
                                          )} peer w-full cursor-text rounded-md border border-gray-300 bg-white ${
                                            props.values.breed ? 'pb-2 pr-8 pl-3 pt-[1.2rem]' : 'p-4'
                                          }`}
                                          theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                              ...theme.colors,
                                              primary25: '#F5F1ED',
                                              primary: '#6BA2B3',
                                              primary50: '#6BA2B3'
                                            }
                                          })}
                                          styles={{
                                            multiValue: (styles, { data }) => {
                                              return {
                                                ...styles,
                                                color: '#004355',
                                                backgroundColor: '#F5F1ED'
                                              }
                                            },
                                            multiValueLabel: (styles, { data }) => ({
                                              ...styles,
                                              color: '#004355'
                                            }),
                                            multiValueRemove: (styles, { data }) => ({
                                              ...styles,
                                              color: '#004355',
                                              ':hover': {
                                                backgroundColor: '#F5F1ED',
                                                color: '#004355'
                                              }
                                            }),
                                            control: (base, _state) => ({
                                              ...base,
                                              zIndex: 9999,
                                              border: 'none',
                                              boxShadow: 'none !important',
                                              '*': {
                                                boxShadow: 'none !important'
                                              },
                                              '&:hover': {
                                                border: 'none !important'
                                              }
                                            }),
                                            valueContainer: (provided, _state) => ({
                                              ...provided,
                                              padding: '0'
                                            }),
                                            menu: provided => ({
                                              ...provided,
                                              borderRadius: '10px'
                                            })
                                          }}
                                          components={{
                                            IndicatorSeparator: () => null
                                          }}
                                          placeholder={props.touched.breed ? null : 'Breed*'}
                                          isMulti
                                          value={props.values.breed}
                                          onChange={(e: any) => {
                                            props.setFieldValue('breed', e)
                                          }}
                                          options={breeds}
                                          isOptionDisabled={() => props?.values?.breed?.length >= 2}
                                          isClearable={false}
                                          onBlur={() => {
                                            props.setFieldTouched('breed', true)
                                          }}
                                          required
                                        />
                                      )}
                                      {!disabled && (
                                        <label
                                          htmlFor="breed"
                                          className={`absolute ${
                                            !props.values.breed && !props.touched.breed && 'hidden'
                                          } -top-2 ml-2.5 inline-flex h-full -translate-y-3 scale-75 transform cursor-text appearance-none items-center duration-300 peer-focus:ml-2.5 peer-focus:-translate-y-3 peer-focus:scale-75`}
                                        >
                                          Breed<span className="ml-1">*</span>
                                        </label>
                                      )}
                                    </div>
                                    {(props.errors.breed && props.touched.breed) ||
                                    (!props.values.breed?.length && props.values.breed !== null && !disabled) ? (
                                      <div className="p-2 text-xs text-red-500">{'Breed is required'}</div>
                                    ) : null}
                                  </div>
                                )}
                                {disabled ? (
                                  <div
                                    role="group"
                                    aria-labelledby="birthday-group"
                                    className="col-span-2 flex flex-wrap items-center py-2"
                                  >
                                    <span className="flex">Date of birth: </span>
                                    <span className="pl-2 font-bold">{getDogDob(props.values)}</span>
                                  </div>
                                ) : (
                                  <div
                                    role="group"
                                    aria-labelledby="birthday-group"
                                    className="col-span-2 flex flex-col"
                                  >
                                    {!disabled && <h4 className="mb-2 text-base">Date of birth</h4>}
                                    <label className="relative flex w-full cursor-text flex-row items-center font-medium">
                                      <span className="sr-only">Date of birth</span>
                                      <div className="inline-flex w-1/2 pr-2 md:w-1/5">
                                        <Field
                                          data-testid="dobDay"
                                          as="select"
                                          name="dobDay"
                                          className={`${checkInputState(
                                            'dobDay'
                                          )} peer w-full cursor-text rounded-md border border-gray-300 bg-white ${
                                            props.values.dobDay ? 'p-2 px-3.5 pt-6' : 'p-4'
                                          } focus:border-secondary focus:ring-secondary disabled:w-auto disabled:border-0 disabled:bg-transparent disabled:bg-none disabled:font-bold disabled:opacity-100`}
                                          disabled={disabled}
                                          onChange={(e: any) => {
                                            if (
                                              checkIfPuppy(
                                                formatDogDob(
                                                  props.values.dobYear,
                                                  props.values.dobMonth,
                                                  e.target.value
                                                )
                                              )
                                            ) {
                                              props.setFieldValue('exercise', 'MEDIUM')
                                            }
                                            props.handleChange(e)
                                          }}
                                        >
                                          <option disabled value="">
                                            Day
                                          </option>
                                          {props.values.dobMonth &&
                                            Array.from(
                                              { length: months.find(e => e.value === props.values.dobMonth).days },
                                              (_, index) => index + 1
                                            ).map(day => (
                                              <option
                                                key={day}
                                                value={day < 10 ? '0' + day.toString() : day}
                                                disabled={
                                                  props.values.dobMonth === currentDate[1] &&
                                                  props.values.dobYear === currentDate[2] &&
                                                  parseInt(day.toString()) > parseInt(currentDate[0])
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {day}
                                              </option>
                                            ))}

                                          {!props.values.dobMonth &&
                                            [...Array(31 - 1 + 1).keys()]
                                              .map(x => x + 1)
                                              .map((day: number) => (
                                                <option
                                                  key={day}
                                                  value={day < 10 ? '0' + day.toString() : day}
                                                  disabled={
                                                    props.values.dobMonth === currentDate[1] &&
                                                    props.values.dobYear === currentDate[2] &&
                                                    parseInt(day.toString()) > parseInt(currentDate[0])
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  {day}
                                                </option>
                                              ))}
                                        </Field>
                                        <label
                                          htmlFor="dobDay"
                                          className={`absolute ${
                                            disabled && 'hidden'
                                          } top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-2.5 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                            props.values.dobDay ? 'ml-2.5 -translate-y-3 scale-75' : 'hidden'
                                          }`}
                                        >
                                          Day
                                        </label>
                                      </div>

                                      {props.errors.dobDay && props.touched.dobDay ? (
                                        <div className="p-2 text-xs text-red-500">{props.errors.dobDay}</div>
                                      ) : null}

                                      <div className="inline-flex w-1/2 md:w-2/5 md:pr-2">
                                        <Field
                                          data-testid="dobMonth"
                                          as="select"
                                          name="dobMonth"
                                          className={`${checkInputState(
                                            'dobMonth'
                                          )} peer w-full cursor-text rounded-md border border-gray-300 bg-white ${
                                            props.values.dobMonth ? 'p-2 px-3.5 pt-6' : 'p-4'
                                          } focus:border-secondary focus:ring-secondary disabled:w-auto disabled:border-0 disabled:bg-transparent disabled:bg-none disabled:font-bold disabled:opacity-100`}
                                          disabled={disabled}
                                          onChange={(e: any) => {
                                            if (
                                              checkIfPuppy(
                                                formatDogDob(props.values.dobYear, e.target.value, props.values.dobDay)
                                              )
                                            ) {
                                              props.setFieldValue('exercise', 'MEDIUM')
                                            }
                                            props.handleChange(e)
                                          }}
                                        >
                                          <option disabled value="">
                                            Month
                                          </option>
                                          {months?.map((month: any, ind: number) => (
                                            <option
                                              value={month.value}
                                              key={ind}
                                              disabled={
                                                props.values.dobYear === currentDate[2] &&
                                                parseInt(month.value) > parseInt(currentDate[1])
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {month.month}
                                            </option>
                                          ))}
                                        </Field>
                                        <label
                                          htmlFor="dobMonth"
                                          className={`absolute ${
                                            disabled && 'hidden'
                                          } top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-2 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                            props.values.dobMonth ? 'ml-2 -translate-y-3 scale-75' : 'hidden'
                                          }`}
                                        >
                                          Month
                                        </label>
                                      </div>

                                      {props.errors.dobMonth && props.touched.dobMonth ? (
                                        <div className="p-2 text-center text-xs text-red-500">
                                          {props.errors.dobMonth}
                                        </div>
                                      ) : null}

                                      <div className="mt-2 inline-flex w-full md:mt-0 md:w-2/5">
                                        <Field
                                          data-testid="dobYear"
                                          as="select"
                                          name="dobYear"
                                          className={`${checkInputState(
                                            'dobYear'
                                          )} peer w-full cursor-text rounded-md border border-gray-300 bg-white ${
                                            props.values.dobYear ? 'p-2 px-3.5 pt-6' : 'p-4'
                                          } focus:border-secondary focus:ring-secondary disabled:w-auto disabled:border-0 disabled:bg-transparent disabled:bg-none disabled:font-bold disabled:opacity-100`}
                                          disabled={disabled}
                                          onChange={(e: any) => {
                                            if (
                                              checkIfPuppy(
                                                formatDogDob(e.target.value, props.values.dobMonth, props.values.dobDay)
                                              )
                                            ) {
                                              props.setFieldValue('exercise', 'MEDIUM')
                                            }
                                            props.handleChange(e)
                                          }}
                                        >
                                          <option disabled value="">
                                            Year *
                                          </option>

                                          {years?.map((year: any) => (
                                            <option value={year}>{year}</option>
                                          ))}
                                        </Field>
                                        <label
                                          htmlFor="dobYear"
                                          className={`absolute ${
                                            disabled && 'hidden'
                                          } top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-2.5 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                            props.values.dobYear ? 'ml-2.5 -translate-y-3 scale-75' : 'hidden'
                                          }`}
                                        >
                                          Year<span className="ml-1">*</span>
                                        </label>
                                      </div>
                                      {props.errors.dobYear && props.touched.dobYear ? (
                                        <div className="p-2 text-right text-xs text-red-500">
                                          {props.errors.dobYear}
                                        </div>
                                      ) : null}
                                    </label>
                                  </div>
                                )}

                                <div
                                  role="group"
                                  aria-labelledby="physique-group"
                                  className="col-span-2 flex flex-col py-2"
                                >
                                  {/* {!disabled && <h4 className="mb-4 text-xl">Body condition</h4>} */}
                                  <div className="relative flex w-full cursor-text flex-row items-center font-medium">
                                    {disabled && <span>Physique:</span>}
                                    <span className="sr-only">Physique</span>
                                    <Field
                                      data-testid="physique"
                                      as="select"
                                      name="physique"
                                      className={`${checkInputState(
                                        'physique'
                                      )} peer w-full cursor-text rounded-md border border-gray-300 bg-white ${
                                        props.values.physique ? 'p-2 px-3.5 pt-6' : 'p-4'
                                      } focus:border-secondary focus:ring-secondary disabled:ml-2 disabled:w-auto disabled:border-0 disabled:bg-transparent disabled:bg-none disabled:p-0 disabled:font-bold disabled:opacity-100`}
                                      disabled={disabled}
                                    >
                                      <option disabled value="">
                                        {dog?.newDog ? 'Physique *' : '-'}
                                      </option>
                                      <option value="SLIM">Slim</option>
                                      <option value="PERFECT">Perfect</option>
                                      <option value="OVERWEIGHT">Chunky</option>
                                    </Field>
                                    <label
                                      htmlFor="physique"
                                      className={`absolute ${
                                        disabled && 'hidden'
                                      } top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                        props.values.physique ? 'ml-1 -translate-y-3 scale-75' : 'hidden'
                                      }`}
                                    >
                                      Physique<span className="ml-1">*</span>
                                    </label>
                                  </div>
                                  {props.errors.physique && props.touched.physique ? (
                                    <div className="p-2 text-xs text-red-500">{props.errors.physique}</div>
                                  ) : null}
                                </div>

                                <div
                                  role="group"
                                  aria-labelledby="medical-group"
                                  className="col-span-2 flex flex-col py-2"
                                >
                                  <div className="relative flex w-full cursor-text flex-row items-center font-medium">
                                    {disabled && <span>Medical conditions or allergies:</span>}
                                    <span className="sr-only">Medical conditions or allergies</span>
                                    <Field
                                      data-testid="medicalConditionOrAllergies"
                                      as="select"
                                      name="medicalConditionOrAllergies"
                                      className={`${checkInputState(
                                        'medicalConditionOrAllergies'
                                      )} peer w-full cursor-text rounded-md border border-gray-300 bg-white ${
                                        props.values.medicalConditionOrAllergies ? 'p-2 px-3.5 pt-6' : 'p-4'
                                      } focus:border-secondary focus:ring-secondary disabled:ml-2 disabled:w-auto disabled:border-0 disabled:bg-transparent disabled:bg-none disabled:p-0 disabled:font-bold disabled:opacity-100`}
                                      disabled={disabled}
                                    >
                                      <option disabled value="">
                                        {dog?.newDog ? 'Medical conditions/allergies *' : '-'}
                                      </option>
                                      <option value="true">Yes</option>
                                      <option value="false">No</option>
                                    </Field>
                                    <label
                                      htmlFor="medicalConditionOrAllergies"
                                      className={`absolute ${
                                        disabled && 'hidden'
                                      } top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-[-1.10rem] peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                        props.values.medicalConditionOrAllergies
                                          ? 'ml-[-1.10rem] -translate-y-3 scale-75'
                                          : 'hidden'
                                      }`}
                                    >
                                      Medical conditions or allergies<span className="ml-1">*</span>
                                    </label>
                                  </div>
                                  {props.errors.medicalConditionOrAllergies &&
                                  props.touched.medicalConditionOrAllergies ? (
                                    <div className="p-2 text-xs text-red-500">
                                      {props.errors.medicalConditionOrAllergies}
                                    </div>
                                  ) : null}
                                </div>

                                <div
                                  role="group"
                                  aria-labelledby="neutered-group"
                                  className="col-span-2 flex flex-col py-2"
                                >
                                  <div className="relative flex w-full cursor-text flex-row items-center font-medium">
                                    {disabled && <span>Neutered or spayed:</span>}
                                    <span className="sr-only">Neutered or spayed</span>
                                    <Field
                                      data-testid="neutered"
                                      as="select"
                                      name="neutered"
                                      className={`${checkInputState(
                                        'neutered'
                                      )} peer w-full cursor-text rounded-md border border-gray-300 bg-white ${
                                        props.values.neutered ? 'p-2 px-3.5 pt-6' : 'p-4'
                                      } focus:border-secondary focus:ring-secondary disabled:ml-2 disabled:w-auto disabled:border-0 disabled:bg-transparent disabled:bg-none disabled:p-0 disabled:font-bold disabled:opacity-100`}
                                      disabled={disabled}
                                    >
                                      <option disabled value="">
                                        {dog?.newDog ? 'Neutered or spayed *' : '-'}
                                      </option>
                                      <option value="true">Yes</option>
                                      <option value="false">No</option>
                                    </Field>
                                    <label
                                      htmlFor="neutered"
                                      className={`absolute ${
                                        disabled && 'hidden'
                                      } top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:-ml-1.5 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                        props.values.neutered ? '-ml-1.5 -translate-y-3 scale-75' : 'hidden'
                                      }`}
                                    >
                                      Neutered or spayed<span className="ml-1">*</span>
                                    </label>
                                  </div>
                                  {props.errors.neutered && props.touched.neutered ? (
                                    <div className="p-2 text-xs text-red-500">{props.errors.neutered}</div>
                                  ) : null}
                                </div>

                                <div
                                  role="group"
                                  aria-labelledby="weight-group"
                                  className="flex flex-col justify-center py-2"
                                >
                                  <div className={`${disabled ? 'flex' : 'block'} cursor-text font-medium`}>
                                    {disabled && <span>Weight:</span>}
                                    <div className="relative flex w-full flex-row items-center">
                                      {!disabled ? (
                                        <>
                                          <Field
                                            id="weight"
                                            data-testid="weight"
                                            type="number"
                                            name="weight"
                                            value={props.values.weight}
                                            className={`${checkInputState(
                                              'weight'
                                            )} peer w-full cursor-text rounded-md border border-gray-300 bg-white pt-6 focus:border-secondary focus:ring-secondary`}
                                            disabled={disabled}
                                            onChange={(e: any) => {
                                              e.currentTarget.value = Math.trunc(e.currentTarget.value)
                                              props.handleChange(e)
                                            }}
                                          />
                                          <label
                                            htmlFor="weight"
                                            className={`absolute ${
                                              disabled && 'hidden'
                                            } top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                              props.values.weight ? 'ml-1 -translate-y-3 scale-75' : 'ml-4'
                                            }`}
                                          >
                                            Weight<span className="ml-1">*</span>
                                          </label>
                                        </>
                                      ) : (
                                        <span data-testid="dogWeight" className="pl-2 font-bold">
                                          {props.values.weight}
                                        </span>
                                      )}
                                      <span className={`${disabled && 'font-bold'} mx-1`}>kg</span>
                                    </div>
                                  </div>
                                  {props.errors.weight && props.touched.weight ? (
                                    <div className="p-2 text-xs text-red-500">{props.errors.weight}</div>
                                  ) : null}
                                </div>
                                {!checkIfPuppy(
                                  formatDogDob(props.values.dobYear, props.values.dobMonth, props.values.dobDay)
                                ) && (
                                  <div
                                    role="group"
                                    aria-labelledby="exercise-group"
                                    className="col-span-2 flex flex-col py-2"
                                  >
                                    <div className="relative flex w-full cursor-text flex-row items-center font-medium">
                                      {disabled && <span>Daily exercise:</span>}
                                      <span className="sr-only">Daily exercise</span>
                                      <Field
                                        data-testid="exercise"
                                        as="select"
                                        name="exercise"
                                        className={`${checkInputState(
                                          'exercise'
                                        )} peer w-full cursor-text rounded-md border border-gray-300 bg-white ${
                                          props.values.exercise ? 'p-2 px-3.5 pt-6' : 'p-4'
                                        } focus:border-secondary focus:ring-secondary disabled:ml-2 disabled:w-auto disabled:border-0 disabled:bg-transparent disabled:bg-none disabled:p-0 disabled:font-bold disabled:opacity-100`}
                                        disabled={disabled}
                                      >
                                        <option disabled value="">
                                          {dog?.newDog ? 'Daily exercise *' : '-'}
                                        </option>
                                        <option value="LOW">{'0-1 hour'}</option>
                                        <option value="MEDIUM">{'1-2 hours'}</option>
                                        <option value="HIGH">{'2+ hours'}</option>
                                      </Field>
                                      <label
                                        htmlFor="exercise"
                                        className={`absolute ${
                                          disabled && 'hidden'
                                        } top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:-ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                          props.values.exercise ? '-ml-0 -translate-y-3 scale-75' : 'hidden'
                                        }`}
                                      >
                                        Daily exercise<span className="ml-1">*</span>
                                      </label>
                                    </div>
                                    {props.errors.exercise && props.touched.exercise ? (
                                      <div className="p-2 text-xs text-red-500">{props.errors.exercise}</div>
                                    ) : null}
                                  </div>
                                )}
                                <Field type="hidden" name="id" />
                                <div
                                  role="group"
                                  aria-labelledby="fussy-group"
                                  className="col-span-2 flex flex-col py-2"
                                >
                                  <div className="relative flex w-full cursor-text flex-row items-center font-medium">
                                    {disabled && <span>Fussiness level:</span>}
                                    <span className="sr-only">Fussiness level</span>
                                    <Field
                                      data-testid="fussy"
                                      as="select"
                                      name="fussy"
                                      className={`${checkInputState(
                                        'fussy'
                                      )} peer w-full cursor-text rounded-md border border-gray-300 bg-white ${
                                        props.values.fussy ? 'p-2 px-3.5 pt-6' : 'p-4'
                                      } focus:border-secondary focus:ring-secondary disabled:ml-2 disabled:w-auto disabled:border-0 disabled:bg-transparent disabled:bg-none disabled:p-0 disabled:font-bold disabled:opacity-100`}
                                      disabled={disabled}
                                    >
                                      <option disabled value="">
                                        {dog?.newDog ? 'Fussiness level *' : '-'}
                                      </option>
                                      <option value="LOW">{'Licks the bowl clean'}</option>
                                      <option value="MEDIUM">{'A picky eater'}</option>
                                      <option value="HIGH">{'A fussy pup'}</option>
                                    </Field>
                                    <label
                                      htmlFor="fussy"
                                      className={`absolute ${
                                        disabled && 'hidden'
                                      } top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                        props.values.fussy || !dog?.newDog ? 'ml-0 -translate-y-3 scale-75' : 'hidden'
                                      }`}
                                    >
                                      Fussiness level<span className="ml-1">*</span>
                                    </label>
                                  </div>
                                  {props.errors.fussy && props.touched.fussy ? (
                                    <div className="p-2 text-xs text-red-500">{props.errors.fussy}</div>
                                  ) : null}
                                </div>
                                {/* {disabled ? (
                                  <div
                                    role="group"
                                    aria-labelledby="daily-grams-group"
                                    className="col-span-2 flex flex-wrap py-2"
                                  >
                                    <span>Approx. daily grams: </span>
                                    <span className="pl-2 font-bold">
                                      {dog?.gramsPerDay ? dog.gramsPerDay + ' g' : 'N/A'}{' '}
                                    </span>
                                  </div>
                                ) : (
                                  ''
                                )} */}
                                {disabled ? (
                                  <div
                                    role="group"
                                    aria-labelledby="daily-calories-group"
                                    className="col-span-2 flex flex-wrap py-2"
                                  >
                                    <span className="flex">Approx. daily calories:</span>
                                    <span className="pl-2 font-bold">
                                      {dog?.kcalPerDay ? dog.kcalPerDay + ' Kcal' : 'N/A'}
                                    </span>
                                  </div>
                                ) : (
                                  ''
                                )}

                                {disabled ? (
                                  <div
                                    role="group"
                                    aria-labelledby="last-updated-group"
                                    className="col-span-2 flex flex-wrap py-2"
                                  >
                                    <span className="flex">Last updated:</span>
                                    <span className="pl-2 font-bold">{dayjs(dog.updatedAt).fromNow()}</span>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {!disabled && (
                                  <div className="sm:col-span-2">
                                    <div className="flex flex-row items-center justify-end">
                                      <button
                                        className="btn-secondary mr-8"
                                        onClick={() => {
                                          setDisabled(true), handleCancelDog(props, i)
                                        }}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        data-testid="submit"
                                        type="button"
                                        onClick={() => {
                                          props.handleSubmit()
                                          props.values.id && setDisabled(true)
                                        }}
                                        className="btn-cta disabled:cursor-not-allowed disabled:opacity-50"
                                        disabled={
                                          (!props.isValid || !props.values.breed?.length || !props.values.fussy) &&
                                          !spoofMode
                                        }
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                )}
                                {disabled && (
                                  <div className="flex flex-col items-center">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setDisabled(false)
                                      }}
                                      data-testid="editDogDetails"
                                      className="btn-primary mb-4 inline-flex w-full items-center justify-center !border-[#2A6482] text-center hover:!bg-[#2A6482] hover:!text-white focus:!bg-[#2A6482] focus:!text-white md:mb-6 md:w-fit"
                                    >
                                      Edit details
                                    </button>
                                    {dog.subscription?.status !== 'paused' && (
                                      <button
                                        data-testid={props.values.name}
                                        type="button"
                                        onClick={() => {
                                          if (!props.values.id) {
                                            let tempDogs: DogType[] = []

                                            dogs.map(dog => {
                                              if (dog.id) {
                                                tempDogs.push(dog)
                                              }
                                            })

                                            dispatch(updateDogs(tempDogs))
                                            setModalShow(false)
                                          } else {
                                            let subscriptionId = ''
                                            let amountOfDogsInPlan = 0
                                            let dogNames = ''

                                            plans.map(plan => {
                                              plan.dogs.map(dog => {
                                                if (dog.id === props.values.id) {
                                                  subscriptionId = plan.subscriptionId
                                                  dogNames = plan.dogNames
                                                }
                                              })
                                            })

                                            plans.map(plan => {
                                              if (plan.subscriptionId === subscriptionId) {
                                                amountOfDogsInPlan = plan?.dogs?.length
                                              }
                                            })

                                            if (amountOfDogsInPlan === 1) {
                                              if (dog.subscription.status === 'cancelled') {
                                                setModalText(
                                                  <div>
                                                    <h3 className="mb-4">Confirm removing {props.values.name}</h3>{' '}
                                                    <span className="block">Do you want to delete this dog?</span>
                                                  </div>
                                                )

                                                setModalButtons(
                                                  <div className="mx-auto grid w-full grid-cols-2">
                                                    <div className="inline-flex justify-center">
                                                      <button
                                                        type="button"
                                                        className="btn-secondary"
                                                        onClick={() => {
                                                          setModalShow(false)
                                                        }}
                                                      >
                                                        No
                                                      </button>
                                                    </div>
                                                    <div className="inline-flex justify-center">
                                                      <button
                                                        data-testid="confirmRemove"
                                                        type="button"
                                                        className="btn-cta"
                                                        onClick={async () => {
                                                          await dispatch(deleteDog(dog.id))
                                                          callDogs()
                                                          setModalShow(false)
                                                        }}
                                                      >
                                                        Yes
                                                      </button>
                                                    </div>
                                                  </div>
                                                )
                                              } else {
                                                setModalText(
                                                  <div>
                                                    <h3 className="mb-4">Confirm removing {props.values.name}</h3>{' '}
                                                    {props.values.name} is the only dog in plan{' '}
                                                    <span className="font-bold">"{props.values.name}"</span>.{' '}
                                                    <span className="block">Do you want to cancel the plan?</span>
                                                  </div>
                                                )

                                                setModalButtons(
                                                  <div className="mx-auto grid w-full grid-cols-2">
                                                    <div className="inline-flex justify-center">
                                                      <button
                                                        type="button"
                                                        className="btn-secondary"
                                                        onClick={() => {
                                                          setModalShow(false)
                                                        }}
                                                      >
                                                        No
                                                      </button>
                                                    </div>
                                                    <div className="inline-flex justify-center">
                                                      <button
                                                        data-testid="confirmRemove"
                                                        type="button"
                                                        className="btn-cta"
                                                        onClick={async () => {
                                                          await dispatch(updateDogsToDelete([props.values.id]))
                                                          const url = spoofMode
                                                            ? `/account/plan/${subscriptionId}/status/cancel/?userId=` +
                                                              spoofedUserId
                                                            : `/account/plan/${subscriptionId}/status/cancel/`
                                                          navigate(url)
                                                        }}
                                                      >
                                                        Yes
                                                      </button>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            } else {
                                              setModalText(
                                                <div>
                                                  <h3 className="mb-4">Confirm removing {props.values.name}.</h3>
                                                  <span>
                                                    {props.values.name} is 1 of {amountOfDogsInPlan} dogs so this plan
                                                    needs to be amended.
                                                  </span>
                                                </div>
                                              )

                                              setModalButtons(
                                                <div className="mx-auto grid w-full grid-cols-2">
                                                  <div className="inline-flex justify-center">
                                                    <button
                                                      type="button"
                                                      className="btn-secondary"
                                                      onClick={() => {
                                                        setModalShow(false)
                                                      }}
                                                    >
                                                      No
                                                    </button>
                                                  </div>
                                                  <div className="inline-flex justify-center">
                                                    <button
                                                      data-testid="confirmRemove"
                                                      type="button"
                                                      className="btn-cta"
                                                      onClick={() => {
                                                        let subscriptionId = ''

                                                        dogs.map(dog => {
                                                          if (dog.id === props.values.id) {
                                                            subscriptionId = dog.subscription.id
                                                          }
                                                        })

                                                        setModalText(
                                                          <span>Do you want to update {dogNames}'s plan?</span>
                                                        )

                                                        setModalButtons(
                                                          <div className="mx-auto grid w-full grid-cols-2">
                                                            <div className="inline-flex justify-center">
                                                              <button
                                                                type="button"
                                                                className="btn-secondary"
                                                                onClick={() => {
                                                                  setModalShow(false)
                                                                }}
                                                              >
                                                                No
                                                              </button>
                                                            </div>
                                                            <div className="inline-flex justify-center">
                                                              <button
                                                                data-testid="yesUpdatePlanBtn"
                                                                type="button"
                                                                className="btn-cta"
                                                                onClick={() => {
                                                                  dispatch(updateDogsToDelete([props.values.id]))
                                                                  dispatch(updatePlanRadio(subscriptionId))
                                                                  dispatch(
                                                                    createDogsExistingPlan({
                                                                      subscriptionId: subscriptionId,
                                                                      dogs: [
                                                                        {
                                                                          id: props.values.id,
                                                                          name: props.values.name,
                                                                          weight: props.values.weight,
                                                                          dob: formatDogDob(
                                                                            props.values.dobYear,
                                                                            props.values.dobMonth,
                                                                            props.values.dobDay
                                                                          ),
                                                                          exercise: props.values.exercise,
                                                                          physique: props.values.physique,
                                                                          neutered: JSON.parse(props.values.neutered),
                                                                          gender: props.values.gender,
                                                                          breed: props.values.breed,
                                                                          medicalConditionOrAllergies: JSON.parse(
                                                                            props.values.medicalConditionOrAllergies
                                                                          ),
                                                                          fussy: props.values.fussy,
                                                                          delete: true
                                                                        }
                                                                      ]
                                                                    })
                                                                  )
                                                                }}
                                                              >
                                                                Yes
                                                              </button>
                                                            </div>
                                                          </div>
                                                        )
                                                      }}
                                                    >
                                                      Yes
                                                    </button>
                                                  </div>
                                                </div>
                                              )
                                            }

                                            setModalShow(true)
                                          }
                                        }}
                                        className="btn-secondary"
                                      >
                                        Remove
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Form>
                        )
                      }}
                    </Formik>
                  )
                })}
            </div>

            {true && dogsHaveRequiredDataForFeedingGuide() && plans?.length > 0 && (
              <h2 className="mt-4 mb-4 text-center">Feeding guide</h2>
            )}
            {true &&
              dogsHaveRequiredDataForFeedingGuide() &&
              plans?.length > 0 &&
              plans
                ?.filter(p => p.status === 'active' || p.status === 'future')
                .map((p: any) => (
                  <div
                    key={p.subscriptionId}
                    className="mx-auto mb-4 w-full rounded-3xl bg-[#F7F4D4] p-4 text-center md:w-1/2 md:min-w-[500px]"
                  >
                    {p.planType === 'FULL' && (
                      <p className="mb-8">
                        <span className="mb-6 block text-center">On a full plan of Different Dog we recommend: </span>
                        {p.dogs.map((d: any, i: number) => (
                          <span key={d.id} className={`${i === 0 ? 'mt-2' : ''} mb-2 block`}>
                            <span className="font-bold">{getGramsPerDay(p, d)}g</span> a day for{' '}
                            <span className="font-bold">{d.name}</span> which is{' '}
                            {getPercentageOfPack(p, d) === 100 && (
                              <span>
                                a <span className="font-bold">whole</span> pack.{' '}
                              </span>
                            )}
                            {getPercentageOfPack(p, d) >= 45 && getPercentageOfPack(p, d) <= 55 && (
                              <span>
                                around <span className="font-bold">half</span> of a pack.{' '}
                              </span>
                            )}
                            {getPercentageOfPack(p, d) < 100 &&
                              !(getPercentageOfPack(p, d) >= 45 && getPercentageOfPack(p, d) <= 55) && (
                                <span>
                                  approximately{' '}
                                  <span className="font-bold">{Math.round(getPercentageOfPack(p, d) / 5) * 5}%</span> of
                                  a pack.{' '}
                                </span>
                              )}
                            {getPercentageOfPack(p, d) > 100 && (
                              <span>
                                {' '}
                                approximately{' '}
                                <span className="font-bold">{(getPercentageOfPack(p, d) / 100).toFixed(1)} </span>
                                packs
                              </span>
                            )}
                          </span>
                        ))}
                      </p>
                    )}
                    {(p.planType === 'PARTIAL' || p.planType === 'PARTIAL_TRIAL' || p.planType === 'CUSTOM') && (
                      <p className="mb-8">
                        {p.dogs?.length > 0 && (
                          <span className="mb-6 block text-center">
                            On a {(p.planType === 'PARTIAL' || p.planType === 'PARTIAL_TRIAL') && 'partial plan'}
                            {p.planType === 'CUSTOM' && 'custom plan'}, if you are feeding{' '}
                            <span className="font-bold">{getCustomPercentage(p)}%</span> of their diet we recommend:
                          </span>
                        )}
                        {p.dogs?.length > 0 &&
                          p.dogs.map((d, i) => (
                            <span className={`${i === 0 ? 'mt-2' : ''} mb-2 block`}>
                              <span className="font-bold">{getGramsPerDay(p, d)}g</span> a day for{' '}
                              <span className="font-bold">{d.name}</span> which is{' '}
                              {getPercentageOfPack(p, d) === 100 && (
                                <span>
                                  a <span className="font-bold">whole</span> pack.{' '}
                                </span>
                              )}
                              {getPercentageOfPack(p, d) >= 45 && getPercentageOfPack(p, d) <= 55 && (
                                <span>
                                  around <span className="font-bold">half</span> of a pack.{' '}
                                </span>
                              )}
                              {getPercentageOfPack(p, d) < 100 &&
                                !(getPercentageOfPack(p, d) >= 45 && getPercentageOfPack(p, d) <= 55) && (
                                  <span>
                                    {' '}
                                    approximately <span className="font-bold">{getPercentageOfPack(p, d)}%</span> of a
                                    pack.{' '}
                                  </span>
                                )}
                              {getPercentageOfPack(p, d) > 100 && (
                                <span>
                                  {' '}
                                  approximately{' '}
                                  <span className="font-bold">{(getPercentageOfPack(p, d) / 100).toFixed(1)} </span>
                                  packs
                                </span>
                              )}
                            </span>
                          ))}
                      </p>
                    )}
                    {p.isInTrial && p.dogs?.length > 1 && (
                      <p className="mb-8">
                        Transitioning on to a new food slowly helps their tummy adjust. Start by adding only around 10%
                        of Different Dog to their current food. Each day, add 10% more, while reducing your current
                        food. After 10 days you will be fully transitioned on to a Different Dog diet!
                      </p>
                    )}
                    {p.isInTrial && p.dogs?.length === 1 && (
                      <p className="mb-8">
                        Transitioning on to a new food slowly helps {p.dogs[0].gender === 'MALE' ? 'his' : 'her'} tummy
                        adjust. Start by adding only around 10% of Different Dog to{' '}
                        {p.dogs[0].gender === 'MALE' ? 'his' : 'her'} current food. Each day, add 10% more, while
                        reducing your current food. After 10 days you will be fully transitioned on to a Different Dog
                        diet!
                      </p>
                    )}
                    {!p.isInTrial && p.planType !== 'CUSTOM' && (
                      <p className="mb-8">
                        Once opened, packs stay fresh for 5 days. One{' '}
                        <span className="font-bold">{getPackSize(p)}</span> pack should last you about{' '}
                        <span className="font-bold">{getPacksPerDay(p)}</span> day
                        {getPacksPerDay(p) !== 1 && 's'}.
                      </p>
                    )}
                    <span className="text-center">
                      To change your plan,{' '}
                      <button
                        className="font-bold underline"
                        onClick={async () => {
                          if (typeof window.gtag !== 'undefined') {
                            window.gtag('event', 'edit_plan', {
                              page: 'account_dogs'
                            })
                          }
                          if (p.redZone) {
                            setRedZoneConfirmButton(
                              <button
                                data-testid="editFutureStatus"
                                type="button"
                                className="btn-cta inline-flex justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
                                onClick={() => {
                                  dispatch(updatePlanRadio(p.subscriptionId))
                                  dispatch(
                                    createDogsExistingPlan({
                                      subscriptionId: p.subscriptionId,
                                      dogs: p.dogs
                                    })
                                  )
                                  const url = spoofMode
                                    ? '/account/plan-recipes/?userId=' + spoofedUserId
                                    : '/account/plan-recipes/'
                                  navigate(url)
                                  setRedZoneModalShow(false)
                                }}
                              >
                                Edit plan
                              </button>
                            )
                            setRedZoneModalShow(true)
                          } else {
                            dispatch(updatePlanRadio(p.subscriptionId))
                            dispatch(
                              createDogsExistingPlan({
                                subscriptionId: p.subscriptionId,
                                dogs: p.dogs
                              })
                            )
                            const url = spoofMode
                              ? '/account/plan-recipes/?userId=' + spoofedUserId
                              : '/account/plan-recipes/'
                            navigate(url)
                          }
                        }}
                      >
                        click here
                      </button>
                    </span>
                  </div>
                ))}

            <div className="relative m-auto mt-32 w-full rounded-3xl bg-[#D7EAD2] p-8 pt-24 md:w-1/2">
              <StaticImage
                imgStyle={{ position: 'absolute' }}
                style={{ top: '-8rem', marginBottom: '-8rem', position: 'absolute' }}
                className="absolute left-0 right-0 m-auto h-56 w-56"
                src={'../../../../images/crumble-top-tip.png'}
                alt="Crumble's Top Tip"
              />
              <h3 className="font-third mb-8 text-center text-5xl">Crumble's top tip</h3>
              <p className="mb-8">
                To update your pup's daily grams, first update their weight or other details and you will be prompted to
                update their plan.
              </p>
              <p>We recommend updating puppies every month. Adult dogs can be updated as and when you see changes.</p>
            </div>
          </main>
        </div>
        <Helmet>{zendeskScript && zendeskScript}</Helmet>
        <Toast showToast={showToast} msg={toastMsg} handleSetShowToast={handleSetShowToast} />
        <DogsModal show={modalShow} setShow={setModalShow} paragraphText={modalText}>
          {modalButtons}
        </DogsModal>
        <EditFutureOrderModal
          show={redZoneModalShow}
          setShow={setRedZoneModalShow}
          confirmButton={redZoneConfirmButton}
        />
        {errorMessage?.length > 0 && (
          <ErrorModal
            show={showPopup}
            setShow={setShowPopup}
            errorMessage={errorMessage}
            url={null}
            action={null}
            actionText={null}
          />
        )}
        <FeedbackForm source={'account'} />
      </div>
    )
  )
}

export default Dogs
